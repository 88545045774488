import React, {
	useCallback,
	useRef,
	useMemo,
	type ComponentType,
	type ComponentPropsWithRef,
	useState,
} from 'react';
import { IconButton } from '@atlaskit/button/new';
import Button from '@atlaskit/button/standard-button';
import DropdownMenu, {
	DropdownItem,
	DropdownItemGroup,
	type CustomTriggerProps,
} from '@atlaskit/dropdown-menu';
import type { OnOpenChangeArgs } from '@atlaskit/dropdown-menu/types';
import GrowDiagonalIcon from '@atlaskit/icon/core/grow-diagonal';
import FeedbackIcon from '@atlaskit/icon/core/migration/feedback';
import SettingsIcon from '@atlaskit/icon/core/migration/settings';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import ShrinkDiagonalIcon from '@atlaskit/icon/core/shrink-diagonal';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ModalTransition } from '@atlaskit/modal-dialog';
import { Box, xcss } from '@atlaskit/primitives';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	EntryKeys,
	ENTRYPOINT_ID_JIRA_PLATFORM,
} from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { setReturnToProjectUrl } from '@atlassian/jira-navigation-apps-sidebar-project-utils/src/index.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { LicensedProducts } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { ShortcutLabel } from '@atlassian/jira-shortcut-label/src/index.tsx';
import type { BoardId } from '@atlassian/jira-software-roadmap-model/src/app/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import { getRoadmapSettingsUrl } from '../../../utils/urls.tsx';
import type { ExportCsvActionPayload } from '../../../state/ui/share/actions.tsx';
import type ShareModal from './share-modal-with-analytics/share-modal/async.tsx';
import messages from './messages.tsx';

type Props = {
	isCMP: boolean;
	boardId: BoardId;
	projectKey: ProjectKey;
	cloudId: string;
	boardType: string;
	canConfigureTimeline: boolean;
	defaultOpen?: boolean; // remove when cleaning up visual-refresh and visual-refresh_drop_6
	ExportImageModal: ComponentType<ComponentPropsWithRef<typeof ShareModal>>;
	onExportCsv: (payload: ExportCsvActionPayload) => void;
};

const licensedProductsToString: (arg1: LicensedProducts) => string = (products) =>
	Object.keys(products)
		.map((productKey) => (products[productKey] ? productKey : ''))
		.join(', ');

const MoreSettings = ({
	isCMP,
	boardId,
	projectKey,
	cloudId,
	boardType,
	canConfigureTimeline,
	defaultOpen = false,
	ExportImageModal,
	onExportCsv,
}: Props) => {
	const [isExportImageModalOpen, setIsExportImageModalOpen] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);

	const exportImageItemRef = useRef<HTMLElement>(null);

	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { push } = useRouterActions();
	const isAdmin = useIsAdmin();
	const { licensedProducts } = useTenantContext();
	const { software: softwareEdition } = useAppEditions();

	const { openFeedbackCollector } = useFeedbackCollectorActions();
	const [isFullscreen, { setIsFullscreen }] = useIsFullscreen();

	const configureRoadmapAnalyticsEvent = createAnalyticsEvent({
		action: 'clicked',
		actionSubject: 'configureRoadmapButtonClicked',
	});

	const navigateToRoadmapSettingsPage = () => {
		fireUIAnalytics(configureRoadmapAnalyticsEvent);
		if (!isCMP) setReturnToProjectUrl();
		const url = getRoadmapSettingsUrl(isCMP, projectKey, boardId);
		push(url);
	};

	const toggleFullscreen = useCallback(() => {
		setIsFullscreen(!isFullscreen);
	}, [isFullscreen, setIsFullscreen]);

	const shortcutsKeyMap: ActiveKeyMap = {
		z: {
			callback: toggleFullscreen,
		},
	};

	const feedbackButtonRef = useRef<HTMLButtonElement>(null);

	const additionalFeedbackEntries = useMemo(
		() => [
			{
				id: EntryKeys.OPT_OUT,
				value: 'No',
			},
			{
				id: EntryKeys.FEEDBACK_COLLECTOR_LOCATION,
				value: 'jsw.roadmap',
			},
			{
				id: EntryKeys.CLOUD_ID,
				value: cloudId,
			},
			{
				id: EntryKeys.IS_SIMPLIFIED_PROJECT,
				value: !isCMP.toString(),
			},
			{
				id: EntryKeys.BOARD_TYPE,
				value: boardType,
			},

			{
				id: EntryKeys.LOCATION,

				value: fg('jfp-magma-undefined-navigator')
					? normaliseUrl(globalThis.window?.location?.href ?? '')
					: normaliseUrl(window?.location?.href ?? ''), // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
			},
			{
				id: EntryKeys.REFERER,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				value: document.referrer ? normaliseUrl(document.referrer) : '',
			},
			{
				id: EntryKeys.USER_AGENT,

				value: fg('jfp-magma-undefined-navigator')
					? globalThis.navigator.userAgent
					: navigator.userAgent, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
			},
			{
				id: EntryKeys.SCREEN_RESOLUTION,

				value: fg('jfp-magma-undefined-navigator')
					? `${globalThis.window?.screen?.width} x ${globalThis.window?.screen?.height}`
					: `${window.screen.width} x ${window.screen.height}`, // eslint-disable-line jira/jira-ssr/no-unchecked-globals-usage
			},
			{
				id: EntryKeys.IS_ADMIN,
				value: isAdmin.toString(),
			},
			{
				id: EntryKeys.SUPPORTED_PROJECT_TYPES,
				value: licensedProductsToString(licensedProducts),
			},
			{
				id: EntryKeys.PROJECT_TYPE,
				value: 'software',
			},
			{
				id: EntryKeys.EDITION,
				value: softwareEdition,
			},
		],
		[boardType, cloudId, isCMP, isAdmin, licensedProducts, softwareEdition],
	);

	const onOpenFeedbackCollector = useCallback(() => {
		openFeedbackCollector({
			entrypointId: ENTRYPOINT_ID_JIRA_PLATFORM,
			feedbackTitle: formatMessage(messages.feedbackCollectorTitle),
			feedbackTitleDetails: formatMessage(messages.feedbackCollectorDescription),
			triggerRef: feedbackButtonRef,
			feedbackContext: additionalFeedbackEntries,
		});
	}, [openFeedbackCollector, formatMessage, additionalFeedbackEntries]);

	const applyVisualRefreshDrop6 = isVisualRefreshEnabled() && fg('visual-refresh_drop_6');

	const handleExportImageClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'exportImage clicked');
		setIsExportImageModalOpen(true);
		setIsMenuOpen(false);
	}, [createAnalyticsEvent]);

	const handleExportSpreadsheetClick = useCallback(() => {
		fireUIAnalytics(createAnalyticsEvent({}), 'exportCsv clicked');
		onExportCsv({
			inferredStartDateHeader: formatMessage(messages.exportSpreadsheetInferredStartDate),
			inferredDueDateHeader: formatMessage(messages.exportSpreadsheetInferredDueDate),
		});
	}, [createAnalyticsEvent, onExportCsv, formatMessage]);

	const handleOpenChange = useCallback(({ isOpen }: OnOpenChangeArgs) => {
		setIsMenuOpen(isOpen);
	}, []);

	const handleExportImageModalClose = useCallback(() => {
		setIsExportImageModalOpen(false);
	}, []);

	const moreMenuTrigger = useCallback(
		({ triggerRef, onClick, ...triggerProps }: CustomTriggerProps) =>
			// eslint-disable-next-line no-nested-ternary
			isVisualRefreshEnabled() ? (
				<IconButton
					{...triggerProps}
					onClick={onClick}
					icon={ShowMoreHorizontalIcon}
					label={
						fg('visual-refresh_drop_3')
							? formatMessage(messages.moreActionsMenuLabel)
							: formatMessage(messages.moreSettingsMenuLabel)
					}
					ref={fg('visual-refresh_drop_6') ? mergeRefs(triggerRef, exportImageItemRef) : triggerRef}
					isTooltipDisabled={false}
				/>
			) : getWillShowNav4() ? (
				<ResponsiveButton
					label={formatMessage(messages.moreSettingsMenuLabel)}
					icon={ShowMoreHorizontalIcon}
					ref={triggerRef}
					onClick={onClick}
					{...triggerProps}
				/>
			) : (
				<Button
					{...triggerProps}
					onClick={onClick}
					aria-label={
						fg('jsw_roadmaps_timeline-fix-a11y-rain')
							? formatMessage(messages.moreActionsMenuLabel)
							: undefined
					}
					ref={triggerRef}
					appearance="subtle"
					iconAfter={<ShowMoreHorizontalIcon label="" color="currentColor" spacing="spacious" />}
				/>
			),
		[formatMessage],
	);

	const exportMenuTrigger = useCallback(
		({ triggerRef: nestedTriggerRef, ...triggerProps }: CustomTriggerProps) => (
			<DropdownItem
				{...triggerProps}
				ref={nestedTriggerRef}
				elemAfter={<ChevronRightIcon label="" color="currentColor" />}
			>
				{formatMessage(messages.exportMenuLabel)}
			</DropdownItem>
		),
		[formatMessage],
	);

	return (
		<>
			<DropdownMenu
				shouldRenderToParent={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? true : undefined}
				testId="roadmap.standard-roadmap.header.more-settings"
				isOpen={applyVisualRefreshDrop6 ? isMenuOpen : undefined}
				onOpenChange={applyVisualRefreshDrop6 ? handleOpenChange : undefined}
				trigger={moreMenuTrigger}
				placement="bottom-end"
				defaultOpen={applyVisualRefreshDrop6 ? undefined : defaultOpen}
			>
				{getWillShowNav4() && !fg('blu-5747-fullscreen-in-nav-header') && (
					<DropdownItemGroup>
						<DropdownItem
							elemBefore={
								// eslint-disable-next-line no-nested-ternary
								applyVisualRefreshDrop6 ? undefined : isFullscreen ? (
									<ShrinkDiagonalIcon label="" color="currentColor" spacing="spacious" />
								) : (
									<GrowDiagonalIcon label="" color="currentColor" spacing="spacious" />
								)
							}
							elemAfter={<ShortcutLabel text={applyVisualRefreshDrop6 ? 'z' : 'Z'} />}
							aria-keyshortcuts={applyVisualRefreshDrop6 ? 'z' : 'Z'}
							onClick={toggleFullscreen}
						>
							{isFullscreen
								? formatMessage(messages.exitFullScreen)
								: formatMessage(messages.enterFullScreen)}
						</DropdownItem>
					</DropdownItemGroup>
				)}
				{applyVisualRefreshDrop6 && (
					<DropdownItemGroup
						hasSeparator={
							getWillShowNav4() &&
							!fg('blu-5747-fullscreen-in-nav-header') &&
							applyVisualRefreshDrop6
						}
					>
						<Box xcss={nestedMenuStyles}>
							<DropdownMenu
								defaultOpen={defaultOpen}
								placement="left-start"
								shouldRenderToParent
								trigger={exportMenuTrigger}
							>
								<DropdownItem onClick={handleExportImageClick} ref={exportImageItemRef}>
									{formatMessage(messages.exportImagePng)}
								</DropdownItem>
								<DropdownItem onClick={handleExportSpreadsheetClick}>
									{formatMessage(messages.exportSpreadsheetCsv)}
								</DropdownItem>
							</DropdownMenu>
						</Box>
					</DropdownItemGroup>
				)}
				{canConfigureTimeline && (
					<DropdownItemGroup hasSeparator={applyVisualRefreshDrop6}>
						<DropdownItem
							onClick={navigateToRoadmapSettingsPage}
							elemBefore={
								fg('visual-refresh_drop_6') ? undefined : (
									<SettingsIcon label="" color="currentColor" spacing="spacious" />
								)
							}
							testId="roadmap.standard-roadmap.header.more-settings.configure-timeline"
						>
							{formatMessage(messages.moreSettingsButtonLabel)}
						</DropdownItem>
					</DropdownItemGroup>
				)}
				{getWillShowNav4() && !(isVisualRefreshEnabled() && fg('visual-refresh_drop_3')) && (
					<DropdownItem
						elemBefore={<FeedbackIcon label="" color="currentColor" spacing="spacious" />}
						onClick={onOpenFeedbackCollector}
						ref={feedbackButtonRef}
						testId="roadmap.standard-roadmap.header.more-settings.feedback"
					>
						{formatMessage(messages.feedbackButtonLabel)}
					</DropdownItem>
				)}
			</DropdownMenu>
			{!fg('blu-5747-fullscreen-in-nav-header') && <Shortcuts keyMap={shortcutsKeyMap} />}
			{applyVisualRefreshDrop6 && (
				<ModalTransition>
					{isExportImageModalOpen && (
						<ExportImageModal
							onClose={handleExportImageModalClose}
							exportButtonRef={exportImageItemRef}
						/>
					)}
				</ModalTransition>
			)}
		</>
	);
};

export default MoreSettings;

const nestedMenuStyles = xcss({ minWidth: '160px' });
