import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag'; // ignore-for-ENGHEALTH-17759
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import messages from './messages.tsx';

type Props = {
	issueKey: string | undefined;
	id: string | number;
	onDismissed: (id: number | string) => void;
	onClearDisplayOption: () => void;
};

export const ItemDisappearsFromDisplayRangeFlag = ({
	issueKey,
	onDismissed,
	id,
	onClearDisplayOption,
}: Props) => {
	const { formatMessage } = useIntl();

	const onDismissedCallback = useCallback(
		(flagId: number | string) => {
			onDismissed(flagId);
		},
		[onDismissed],
	);

	const onClick = useCallback(() => {
		onDismissed(id);
		onClearDisplayOption();
	}, [onDismissed, id, onClearDisplayOption]);

	return (
		<SuccessFlag
			id={id}
			title={
				<FormattedMessage
					{...messages.title}
					values={{
						issueKey,
						// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
						b: (chunks: string) => <b>{chunks}</b>,
					}}
				/>
			}
			description={
				<FormattedMessage
					{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionIssueTermRefresh
						: messages.description)}
					values={{
						issueKey,
						// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
						b: (chunks: string) => <b>{chunks}</b>,
					}}
				/>
			}
			onDismissed={onDismissedCallback}
			messageId="software-roadmap-standard.flags.item-disappears-from-display-range.success-flag"
			messageType="transactional"
			actions={[
				{
					content: formatMessage(
						expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
							? messages.clearDisplayOptionIssueTermRefresh
							: messages.clearDisplayOption,
					),
					onClick,
				},
			]}
		/>
	);
};

ItemDisappearsFromDisplayRangeFlag.defaultProps = {
	onDismissed: noop,
};

export default ItemDisappearsFromDisplayRangeFlag;
