/** @jsx jsx */
import React, { type MouseEvent, type ReactNode } from 'react';
import { jsx } from '@compiled/react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { Box, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useIsProjectThemed } from '../../../controller/project-theme-provider/ProjectThemeProvider.tsx';
import messages from './messages.tsx';

type Props = {
	onClick: (event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => void;
	label?: ReactNode | string;
};

const ClearFilterButton = (props: Props) => {
	const { formatMessage } = useIntl();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isThemed = getIsSoftwareThemingEnabled() && useIsProjectThemed();

	const ButtonComponent = isThemed ? ThemedButton : Button;

	return (
		<Box
			xcss={[!isVisualRefreshEnabled() && styledClearFilterStyles]}
			data-test-id="filters.ui.filters.clear-button.ak-button"
			testId="filters.ui.filters.clear-button.ak-button"
		>
			<ButtonComponent
				appearance={isVisualRefreshEnabled() && !fg('visual-refresh_drop_2') ? 'default' : 'subtle'}
				onClick={props.onClick}
			>
				{props.label || formatMessage(messages.label)}
			</ButtonComponent>
		</Box>
	);
};

ClearFilterButton.defaultProps = {
	onClick: noop,
};

export default ClearFilterButton;

const styledClearFilterStyles = xcss({
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});
