import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
// eslint-disable-next-line jira/styled/no-styled-import-alias
import { SkeletonCompiled as Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

type Props = {
	isReleasesFeatureEnabled: boolean;
};

type ToggleProps = {
	width: string;
};

const Toggle = ({ width }: ToggleProps) => (
	<ToggleItem>
		<Skeleton width={width} height="20px" />
		<ToggleButton width="32px" height="16px" />
	</ToggleItem>
);

const ViewSettingsMenuSkeleton = ({ isReleasesFeatureEnabled = false }: Props) => (
	<Container>
		<Section>
			<SectionTitle width="85px" height="16px" />
			<Toggle width="130px" />
			<Item>
				<Skeleton width="240px" height="32px" />
			</Item>
			<WrapTextItem>
				<Skeleton width="350px" height="14.5px" />
				<Skeleton width="120px" height="14.5px" />
			</WrapTextItem>
		</Section>

		<Section>
			<ButtonItem>
				<Skeleton width="100px" height="16px" />
			</ButtonItem>
			<ButtonItem>
				<Skeleton width="110px" height="16px" />
			</ButtonItem>
		</Section>

		<Section>
			<SectionTitle width="80px" height="16px" />
			<Toggle width="80px" />
			<Toggle width="70px" />
			<Toggle width="75px" />
			{isReleasesFeatureEnabled && <Toggle width="70px" />}
		</Section>

		<LearnMoreItem>
			<Skeleton width="240px" height="16px" />
		</LearnMoreItem>
	</Container>
);

export default ViewSettingsMenuSkeleton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	width: '400px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Section = styled.div({
	paddingTop: token('space.075'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.075'),
	paddingLeft: token('space.200'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	'&:not(:first-of-type)': {
		borderTop: `${token('space.025')} solid ${token('color.border')}`,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Item = styled.div({
	display: 'flex',
	width: '100%',
	boxSizing: 'border-box',
	minHeight: token('space.300'),
	// eslint-disable-next-line @atlaskit/platform/expand-spacing-shorthand
	margin: `${token('space.100')} 0`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ToggleItem = styled(Item)({
	height: '28px',
	alignItems: 'center',
	justifyContent: 'space-between',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const WrapTextItem = styled(Item)({
	flexDirection: 'column',
	gap: token('space.050'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonItem = styled(Item)({
	height: '40px',
	alignItems: 'center',
	margin: 0,
	paddingTop: token('space.100'),
	paddingRight: 0,
	paddingBottom: token('space.100'),
	paddingLeft: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const LearnMoreItem = styled(Item)({
	paddingTop: 0,
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionTitle = styled(Skeleton)({
	margin: '14px 0 6px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ToggleButton = styled(Skeleton)({
	marginTop: token('space.050'),
	marginRight: token('space.050'),
	marginBottom: token('space.050'),
	marginLeft: token('space.050'),
	borderRadius: '16px',
});
