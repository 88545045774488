import React, { type PropsWithChildren, useCallback, useState } from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import InformationIcon from '@atlaskit/icon/core/information';
import type { TriggerProps } from '@atlaskit/popup';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import {
	useAnalyticsEvents,
	fireTrackAnalytics,
	fireUIAnalytics,
	type UIAnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import messages from './messages.tsx';
import ShrinkToFit from './shrink-to-fit/index.tsx';
import type { Placement } from './types.tsx';

type Props = {
	/**
	 * Whether to show the label on the legend trigger button
	 */
	appearance?: 'icon' | 'full';
	/**
	 * The position of the legend content
	 */
	placement?: Placement;
	/**
	 * Determines whether the icon button should be hidden from assistive technology
	 */
	isHidden?: boolean;
};

const LegendNew = ({
	children,
	appearance = 'icon',
	placement = 'top-end',
	isHidden,
}: PropsWithChildren<Props>) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, 'legend closed');

		setIsOpen(false);
	}, [createAnalyticsEvent, setIsOpen]);

	const onToggle = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'legendButton');
			fireTrackAnalytics(analyticsEvent, `legend ${isOpen ? 'closed' : 'opened'}`);

			setIsOpen((value) => !value);
		},
		[isOpen],
	);

	const renderTrigger = useCallback(
		(triggerProps: TriggerProps) => {
			switch (appearance) {
				case 'full':
					return (
						<Button
							{...triggerProps}
							isSelected={isOpen}
							onClick={onToggle}
							iconBefore={InformationIcon}
							{...(fg('platform_button_item-add-ufo-metrics')
								? { interactionName: 'timeline-legend-trigger-clicked' }
								: {})}
							{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
								? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
								: {})}
						>
							{formatMessage(messages.legend)}
						</Button>
					);
				case 'icon':
				default:
					return (
						<IconButton
							{...triggerProps}
							isSelected={isOpen}
							onClick={onToggle}
							icon={InformationIcon}
							isTooltipDisabled={false}
							label={formatMessage(messages.legend)}
							tooltip={{
								position: 'top',
							}}
							appearance="subtle"
							{...(fg('platform_button_item-add-ufo-metrics')
								? { interactionName: 'timeline-legend-trigger-clicked' }
								: {})}
							{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
								? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
								: {})}
						/>
					);
			}
		},
		[appearance, formatMessage, isHidden, isOpen, onToggle],
	);

	const renderContent = useCallback(
		() => (
			<ShrinkToFit placement={placement}>
				<Box xcss={legendContentStyles} paddingInline="space.200" paddingBlock="space.250">
					<Stack space="space.250">{children}</Stack>
				</Box>
			</ShrinkToFit>
		),
		[children, placement],
	);

	return (
		<Popup
			id="timeline-legend"
			role="dialog"
			isOpen={isOpen}
			placement={placement}
			onClose={onClose}
			trigger={renderTrigger}
			content={renderContent}
			label={formatMessage(messages.legend)}
			shouldUseCaptureOnOutsideClick
			messageId="aais-timeline-legend.ui.legend.popup"
			messageType="transactional"
		/>
	);
};

const LegendOld = ({ children, isHidden }: PropsWithChildren<Props>) => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onClose = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});

		fireTrackAnalytics(analyticsEvent, 'legend closed');

		setIsOpen(false);
	}, [createAnalyticsEvent, setIsOpen]);

	const onToggle = useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, analyticsEvent: UIAnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'legendButton');
			fireTrackAnalytics(analyticsEvent, `legend ${isOpen ? 'closed' : 'opened'}`);

			setIsOpen((value) => !value);
		},
		[isOpen],
	);
	const renderContent = useCallback(
		() => (
			<ShrinkToFit>
				<Box xcss={legendContentStyles} paddingInline="space.200" paddingBlock="space.250">
					<Stack space="space.250">{children}</Stack>
				</Box>
			</ShrinkToFit>
		),
		[children],
	);

	return (
		<Popup
			isOpen={isOpen}
			placement="top-end"
			onClose={onClose}
			trigger={(triggerProps) => (
				<IconButton
					{...triggerProps}
					isSelected={isOpen}
					onClick={onToggle}
					icon={InformationIcon}
					isTooltipDisabled={false}
					label={formatMessage(messages.legend)}
					tooltip={{
						position: 'top',
					}}
					appearance="subtle"
					{...(fg('platform_button_item-add-ufo-metrics')
						? { interactionName: 'timeline-legend-trigger-clicked' }
						: {})}
					{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
						? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
						: {})}
				/>
			)}
			content={renderContent}
			label={formatMessage(messages.legend)}
			id="timeline-legend"
			role="dialog"
			shouldUseCaptureOnOutsideClick
		/>
	);
};

export default componentWithFG('smart_links_for_plans', LegendNew, LegendOld);

const legendContentStyles = xcss({
	boxSizing: 'content-box',
	minWidth: `calc(${token('space.100')} * 35)`,
});
