import React, { useCallback, useMemo, type MouseEvent, type KeyboardEvent } from 'react';
import { MenuGroup, Section } from '@atlaskit/menu';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	REMOVE_DUE_DATE,
	REMOVE_START_DATE,
	REMOVE_START_DATE_AND_DUE_DATE,
} from '../../../../../constants.tsx';
import messages from './messages.tsx';
import type { Props } from './types.tsx';

const RemoveDatesOption = ({
	isDateEditable,
	isStartDateRequired,
	isDueDateRequired,
	isStartDateRolledUp,
	isDueDateRolledUp,
	isStartDateClearable,
	isDueDateClearable,
	Option,
	onClear,
	setCloseMenu,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const handleOnClick = useCallback(
		(isStartDateCleared: boolean, isDueDateCleared: boolean) =>
			(event: MouseEvent | KeyboardEvent) => {
				event.preventDefault();
				if (isStartDateCleared || isDueDateCleared) {
					const analyticsEvent = createAnalyticsEvent({
						action: 'clicked',
						actionSubject: 'option',
					});

					const attributes = {
						isStartDateCleared,
						isDueDateCleared,
					};

					if (!fg('platform_button_item-add-ufo-metrics')) {
						let ufoInteractionName = 'timeline-remove-start-date-option-clicked';

						if (isStartDateCleared && isDueDateCleared) {
							ufoInteractionName = 'timeline-remove-both-dates-option-clicked';
						} else if (isDueDateCleared) {
							ufoInteractionName = 'timeline-remove-due-date-option-clicked';
						}

						traceUFOPress(ufoInteractionName, event.timeStamp);
					}

					fireUIAnalytics(analyticsEvent, 'removeDatesOption', attributes);
					onClear(
						{ isClearStart: isStartDateCleared, isClearDue: isDueDateCleared },
						analyticsEvent,
					);
				}
				setCloseMenu(event);
			},
		[createAnalyticsEvent, onClear, setCloseMenu],
	);

	const getDisabledTooltip = useCallback(
		(isDateRequired: boolean, isDateRolledUp: boolean) => {
			if (isDateRequired) {
				return formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.requiredDatesIssueTermRefresh
						: messages.requiredDates,
				);
			}

			if (isDateRolledUp) {
				return formatMessage(
					fg('jira-issue-terminology-refresh-m3')
						? messages.rolledUpDatesIssueTermRefresh
						: messages.rolledUpDates,
				);
			}

			return undefined;
		},
		[formatMessage],
	);

	const options = useMemo(
		() => [
			{
				optionId: REMOVE_START_DATE,
				isEnabled: isStartDateClearable,
				label: formatMessage(messages.removeStartDate),
				disabledTooltip: getDisabledTooltip(isStartDateRequired, isStartDateRolledUp),
				...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-remove-start-date-option-clicked' }
					: {}),
				onClick: handleOnClick(true, false),
			},
			{
				optionId: REMOVE_DUE_DATE,
				isEnabled: isDueDateClearable,
				label: formatMessage(messages.removeDueDate),
				disabledTooltip: getDisabledTooltip(isDueDateRequired, isDueDateRolledUp),
				...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-remove-due-date-option-clicked' }
					: {}),
				onClick: handleOnClick(false, true),
			},
			{
				optionId: REMOVE_START_DATE_AND_DUE_DATE,
				isEnabled: isStartDateClearable && isDueDateClearable,
				label: formatMessage(messages.removeBothDates),
				disabledTooltip: getDisabledTooltip(
					isStartDateRequired || isDueDateRequired,
					isStartDateRolledUp || isDueDateRolledUp,
				),
				...(fg('platform_button_item-add-ufo-metrics')
					? { interactionName: 'timeline-remove-both-dates-option-clicked' }
					: {}),
				onClick: handleOnClick(true, true),
			},
		],
		[
			isStartDateRequired,
			isDueDateRequired,
			isStartDateRolledUp,
			isDueDateRolledUp,
			isStartDateClearable,
			isDueDateClearable,
			formatMessage,
			getDisabledTooltip,
			handleOnClick,
		],
	);
	const initialFocusIndex = useMemo(
		() => options.findIndex(({ isEnabled }) => isEnabled === true),
		[options],
	);

	return (
		<Option
			isDisabled={!isDateEditable || (!isStartDateClearable && !isDueDateClearable)}
			label={formatMessage(messages.removeDates)}
			{...(fg('platform_button_item-add-ufo-metrics')
				? { interactionName: 'timeline-remove-dates-primary-option-clicked' }
				: {})}
		>
			{({ setInitialFocusRef }) => (
				<MenuGroup>
					<Section isList>
						{options.map(({ optionId, isEnabled, ...optionProps }, index) => (
							<Option
								key={optionId}
								isDisabled={!isEnabled}
								setInitialFocusRef={index === initialFocusIndex ? setInitialFocusRef : undefined}
								{...optionProps}
							/>
						))}
					</Section>
				</MenuGroup>
			)}
		</Option>
	);
};

export default RemoveDatesOption;
