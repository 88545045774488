/** @jsx jsx */
import React, { useState, type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import ArrowLeftIcon from '@atlaskit/icon/core/migration/arrow-left';
import ArrowRightIcon from '@atlaskit/icon/core/migration/arrow-right';
import { N70 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { Box, Pressable, xcss, type BackgroundColor, type Layer } from '@atlaskit/primitives';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useIsScrollingX } from '@atlassian/timeline-table/common/context/scroll-meta';
import { useViewport } from '@atlassian/timeline-table/common/context/viewport/context';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { CHART_NAVIGATE_BUTTON_Z_INDEX } from '../../../../common/constants/z-index.tsx';
import { NAVIGATE_OFFSET } from './constants.tsx';
import messages from './messages.tsx';

// Constants //

const BUTTON_SIZE = 3 * gridSize;
const DATE_OFFSET = BUTTON_SIZE + gridSize;
const SCROLL_BAR_OFFSET = 10;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const PAST_BORDER_RADIUS = `0 ${borderRadius}px ${borderRadius}px 0`;
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const FUTURE_BORDER_RADIUS = `${borderRadius}px 0 0 ${borderRadius}px`;

export type Props = {
	// Current pixel boundaries of the chart-item-content. Will be used to check whether the item is before or after the visible viewport.
	itemMinLeftPosition: number;
	itemMaxRightPosition: number;
	// Label to be shown when the button is hovered and the item is before or after the visible viewport.
	label: ReactNode;
};

const RoadmapNavigateButton = ({ itemMinLeftPosition, itemMaxRightPosition, label }: Props) => {
	const { formatMessage } = useIntl();
	const { requestViewportScrollBounding, setViewportScroll } = useViewport();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [isScrollingX] = useIsScrollingX();
	const [isHovered, setIsHovered] = useState(false);

	if (isScrollingX) {
		return null;
	}

	const { scrollLeft, scrollRight, scrollWidth, isOverflowY } = requestViewportScrollBounding();
	const isItemInPast = scrollWidth - itemMaxRightPosition < scrollLeft;
	const isItemInFuture = scrollWidth - itemMinLeftPosition < scrollRight;

	// The chart-item-content is visible in the viewport, no need to navigate to it.
	if (!isItemInPast && !isItemInFuture) {
		return null;
	}

	// Callbacks //

	const onMouseEnter = () => {
		setIsHovered(true);
	};

	const onMouseLeave = () => {
		setIsHovered(false);
	};

	const onClick = () => {
		const analyticsEvent = createAnalyticsEvent({ action: 'clicked', actionSubject: 'button' });
		const analyticsAttributes = { direction: isItemInPast ? 'past' : 'future' };
		fireUIAnalytics(analyticsEvent, 'navigateButton', analyticsAttributes);
		setViewportScroll({ scrollLeft: itemMinLeftPosition - NAVIGATE_OFFSET });
	};

	const shouldApplyVisualRefreshChanges = isVisualRefreshEnabled();

	// Render //
	const renderArrowIcon = (Icon: typeof ArrowLeftIcon) => (
		<Icon
			label=""
			color={shouldApplyVisualRefreshChanges ? token('color.icon.inverse') : token('color.icon')}
		/>
	);

	if (isItemInPast) {
		if (fg('jsw_roadmaps_timeline-fix-a11y-rain')) {
			return (
				<Pressable
					xcss={[
						shouldApplyVisualRefreshChanges ? baseButtonXcss : baseButtonXcssOld,
						shouldApplyVisualRefreshChanges ? undefined : pastButtonBorderRadiusXcss,
					]}
					style={{
						left: `${scrollLeft}px`,
						width: `${BUTTON_SIZE}px`,
					}}
					testId="roadmap.timeline-table-kit.ui.chart-item-content.date-content.navigate-button.navigate"
					onClick={onClick}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					aria-label={formatMessage(messages.navigate)}
				>
					{renderArrowIcon(ArrowLeftIcon)}
					{isHovered && (
						<Box as="span" xcss={pastLabelContainerXcss}>
							{label}
						</Box>
					)}
				</Pressable>
			);
		}

		return (
			<div
				css={[
					shouldApplyVisualRefreshChanges ? baseButtonStyles : baseButtonStylesOld,
					shouldApplyVisualRefreshChanges ? undefined : pastButtonBorderRadiusStyles,
				]}
				style={{
					left: `${scrollLeft}px`,
					width: `${BUTTON_SIZE}px`,
				}}
				data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.navigate-button.navigate"
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onKeyDown={(e) => (e.key === 'Enter' || e.key === 'Space') && onClick()}
				role="presentation"
			>
				{renderArrowIcon(ArrowLeftIcon)}
				{isHovered && <div css={pastLabelContainerStyles}>{label}</div>}
			</div>
		);
	}

	if (fg('jsw_roadmaps_timeline-fix-a11y-rain')) {
		return (
			<Pressable
				xcss={[
					shouldApplyVisualRefreshChanges ? baseButtonXcss : baseButtonXcssOld,
					shouldApplyVisualRefreshChanges ? undefined : futureButtonBorderRadiusXcss,
				]}
				style={{
					right: `${scrollRight}px`,
					width: shouldApplyVisualRefreshChanges
						? undefined
						: `${BUTTON_SIZE + (isOverflowY ? SCROLL_BAR_OFFSET : 0)}px`,
				}}
				testId="roadmap.timeline-table-kit.ui.chart-item-content.date-content.navigate-button.navigate"
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				aria-label={formatMessage(messages.navigate)}
			>
				{renderArrowIcon(ArrowRightIcon)}
				{isHovered && (
					<Box
						as="span"
						xcss={futureLabelContainerXcss}
						style={{
							right: `${DATE_OFFSET + (isOverflowY ? SCROLL_BAR_OFFSET : 0)}px`,
						}}
					>
						{label}
					</Box>
				)}
			</Pressable>
		);
	}

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<div
			css={[
				shouldApplyVisualRefreshChanges ? baseButtonStyles : baseButtonStylesOld,
				shouldApplyVisualRefreshChanges ? undefined : futureButtonBorderRadiusStyles,
			]}
			style={{
				right: `${scrollRight}px`,
				width: shouldApplyVisualRefreshChanges
					? undefined
					: `${BUTTON_SIZE + (isOverflowY ? SCROLL_BAR_OFFSET : 0)}px`,
			}}
			data-testid="roadmap.timeline-table-kit.ui.chart-item-content.date-content.navigate-button.navigate"
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{renderArrowIcon(ArrowRightIcon)}
			{isHovered && (
				<div
					css={futureLabelContainerStyles}
					style={{
						right: `${DATE_OFFSET + (isOverflowY ? SCROLL_BAR_OFFSET : 0)}px`,
					}}
				>
					{label}
				</div>
			)}
		</div>
	);
};

export { RoadmapNavigateButton };

const baseButtonXcss = xcss({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	height: '24px',
	width: '24px',
	boxSizing: 'border-box',
	marginLeft: 'space.050',
	marginRight: 'space.050',
	borderRadius: 'border.radius',
	paddingTop: 'space.050',
	paddingRight: 'space.050',
	paddingBottom: 'space.050',
	paddingLeft: 'space.050',
	cursor: 'pointer',
	justifyContent: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-imported-style-values
	backgroundColor: token('color.chart.neutral') as BackgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions
	zIndex: String(CHART_NAVIGATE_BUTTON_Z_INDEX) as Layer,
	':hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-imported-style-values
		backgroundColor: token('color.chart.neutral.hovered') as BackgroundColor,
	},
});

const baseButtonXcssOld = xcss({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	height: '24px',
	boxSizing: 'border-box',
	paddingTop: 'space.050',
	paddingRight: 'space.050',
	paddingBottom: 'space.050',
	paddingLeft: 'space.050',
	backgroundColor: 'color.background.neutral',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions
	zIndex: String(CHART_NAVIGATE_BUTTON_Z_INDEX) as Layer,
	cursor: 'pointer',
	':hover': {
		backgroundColor: 'color.background.neutral.hovered',
	},
});

const futureButtonBorderRadiusXcss = xcss({
	borderRadius: FUTURE_BORDER_RADIUS,
});

const futureLabelContainerXcss = xcss({
	position: 'absolute',
});

const pastButtonBorderRadiusXcss = xcss({
	borderRadius: PAST_BORDER_RADIUS,
});

const pastLabelContainerXcss = xcss({
	position: 'absolute',
	left: 'space.400',
});

const baseButtonStyles = css({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	height: '24px',
	width: '24px',
	boxSizing: 'border-box',
	marginLeft: token('space.050'),
	marginRight: token('space.050'),
	borderRadius: token('border.radius'),
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	backgroundColor: token('color.chart.neutral'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_NAVIGATE_BUTTON_Z_INDEX,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.chart.neutral.hovered'),
	},
});

const baseButtonStylesOld = css({
	position: 'absolute',
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${3 * gridSize}px`,
	boxSizing: 'border-box',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	backgroundColor: token('color.background.neutral', N70),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: CHART_NAVIGATE_BUTTON_Z_INDEX,
	cursor: 'pointer',
	'&:hover': {
		backgroundColor: token('color.background.neutral.hovered', N70),
	},
});

const pastButtonBorderRadiusStyles = css({
	borderRadius: PAST_BORDER_RADIUS,
});

const futureButtonBorderRadiusStyles = css({
	borderRadius: FUTURE_BORDER_RADIUS,
});

const pastLabelContainerStyles = css({
	position: 'absolute',
	left: token('space.400'),
});

const futureLabelContainerStyles = css({
	position: 'absolute',
});
