import difference from 'lodash/difference';
import type { RoadmapChildIssuePlanningMode } from '@atlassian/jira-relay/src/__generated__/roadmapCriticalDataQuery.graphql';
import type { ComponentsHash } from '@atlassian/jira-software-roadmap-model/src/component/index.tsx';
import {
	type Configuration,
	type BoardConfiguration,
	type ProjectConfiguration,
	type PlanningMode,
	DATE,
	DISABLED,
	SPRINT,
} from '@atlassian/jira-software-roadmap-model/src/configuration/index.tsx';
import { DEFAULT_LEVEL_ONE_VIEW_SETTINGS } from '@atlassian/jira-software-roadmap-model/src/filter/index.tsx';
import type { Healthcheck } from '@atlassian/jira-software-roadmap-model/src/healthcheck/index.tsx';
import { DEFAULT_PANEL_RATIO } from '@atlassian/jira-software-roadmap-model/src/settings/constants.tsx';
import type {
	Settings,
	CreationPreferences,
	ItemTypeHash,
	TimelineMode,
} from '@atlassian/jira-software-roadmap-model/src/settings/index.tsx';
import type { SprintsHash } from '@atlassian/jira-software-roadmap-model/src/sprint/index.tsx';
import type { UserHash } from '@atlassian/jira-software-roadmap-model/src/user/index.tsx';
import type { VersionsHash } from '@atlassian/jira-software-roadmap-model/src/version/index.tsx';
import { MONTHS, DEFAULT_LIST_WIDTH } from '@atlassian/timeline-table/common/constants';
import { getToday } from '@atlassian/jira-software-roadmap-utils/src/utils/dates.tsx';
import { dateToUtcTimestamp, optionalDateToUtcTimestamp } from '../common/date.tsx';
import { nullableToOptional } from '../common/field.tsx';
import { transformStatusCategories } from '../common/status-categories.tsx';
import { transformDerivedFieldsHash } from '../configuration/transform-derived-fields-hash.tsx';
import { transformIssues } from '../issues/transformers.tsx';
import type { GraphQLMe } from '../users/types.tsx';
import type {
	GraphQLCriticalDataResponse,
	GraphQLItemType,
	GraphQLRoadmapConfiguration,
	GraphQLCreationPreferences,
	GraphQLUserConfiguration,
	GraphQLBoardConfiguration,
	GraphQLSprint,
	GraphQLVersion,
	GraphQLComponent,
	GraphQLProjectConfiguration,
	CriticalDataResponse,
	RawGraphQLRoadmapConfiguration,
} from './types.tsx';

export const transformHealthcheck = (healthcheck?: Healthcheck | null): Healthcheck | undefined => {
	if (healthcheck) return healthcheck;
	return undefined;
};

export const transformCurrentUser = (currentUser?: GraphQLMe): UserHash | undefined =>
	currentUser
		? {
				[currentUser.accountId]: {
					displayName: currentUser.name,
					avatarUrls: {
						'16x16': currentUser.picture,
						'24x24': currentUser.picture,
						'32x32': currentUser.picture,
						'48x48': currentUser.picture,
					},
				},
			}
		: undefined;

export const transformRoadmapConfiguration = ({
	dependencies,
	externalConfiguration,
	...rest
}: RawGraphQLRoadmapConfiguration): GraphQLRoadmapConfiguration => {
	const { isDependenciesEnabled, ...dependenciesRest } = dependencies;

	return {
		...rest,
		dependencies: {
			...dependenciesRest,
			dependenciesEnabled: isDependenciesEnabled,
		},
		externalConfiguration,
	};
};

const transformItemTypes = (itemTypes: GraphQLItemType[]) =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	itemTypes.reduce<Record<string, any>>((acc, itemType) => {
		acc[itemType.id] = {
			iconUrl: itemType.iconUrl,
			name: itemType.name,
		};
		return acc;
	}, {});

const transformVersions = (versions: ReadonlyArray<GraphQLVersion>): VersionsHash =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	versions.reduce<Record<string, any>>((acc, version) => {
		acc[version.id] = {
			name: version.name,
			status: version.status,
			releaseDate: optionalDateToUtcTimestamp(nullableToOptional(version.releaseDate)),
		};

		return acc;
	}, {});

const transformComponents = (components: ReadonlyArray<GraphQLComponent>): ComponentsHash =>
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	components.reduce<Record<string, any>>((acc, component) => {
		acc[component.id] = component;
		return acc;
	}, {});

const REQUIRED_FIELD_WHITELIST = [
	'reporter', // auto-filled in by backend create
	'issuetype', // supplied as a required argument
	'project', // supplied as a required argument
	'summary', // supplied as a required argument
];
export const transformRequiredFields = (itemTypes: GraphQLItemType[]) => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const requiredFieldsHash: Record<string, any> = {};

	itemTypes.forEach((itemType: GraphQLItemType) => {
		requiredFieldsHash[itemType.id] = difference(
			itemType.requiredFieldIds,
			REQUIRED_FIELD_WHITELIST,
		);
	});

	return requiredFieldsHash;
};

const transformProjectConfig = (
	projectConfig: GraphQLProjectConfiguration,
): ProjectConfiguration => {
	const itemTypes = [...projectConfig.parentItemTypes, ...projectConfig.childItemTypes];

	const defaultItemTypeId =
		projectConfig.defaultItemTypeId !== null && projectConfig.defaultItemTypeId !== undefined
			? projectConfig.defaultItemTypeId
			: undefined;

	return {
		id: projectConfig.projectId,
		key: projectConfig.projectKey,
		name: projectConfig.projectName,
		permissions: { ...projectConfig.permissions },
		epicIssueTypeIds: projectConfig.parentItemTypes.map(
			(epicItemType: GraphQLItemType) => epicItemType.id,
		),
		requiredFields: transformRequiredFields(itemTypes),
		issueTypes: {
			hash: transformItemTypes(itemTypes),
		},
		versions: {
			hash: transformVersions(projectConfig.versions),
		},
		components: {
			hash: transformComponents(projectConfig.components),
		},
		defaultItemTypeId,
		isReleasesFeatureEnabled: projectConfig.isReleasesFeatureEnabled,
		isGoalsFeatureEnabled: projectConfig.isGoalsFeatureEnabled,
	};
};

const transformSprintConfiguration = (sprintConfigs: GraphQLSprint[]): SprintsHash => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const sprintConfigHash: Record<string, any> = {};

	sprintConfigs.forEach((sprintConfig) => {
		const { id, name, state, startDateRFC3339, endDateRFC3339 } = sprintConfig;
		sprintConfigHash[id] = {
			id,
			name,
			state,
			startDate: dateToUtcTimestamp(startDateRFC3339),
			endDate: dateToUtcTimestamp(endDateRFC3339),
		};
	});
	return sprintConfigHash;
};

const transformChildIssuePlanningMode = (
	childIssuePlanningMode: RoadmapChildIssuePlanningMode,
): PlanningMode => {
	switch (childIssuePlanningMode) {
		case 'DISABLED': {
			return DISABLED;
		}
		case 'DATE': {
			return DATE;
		}
		case 'SPRINT':
		default: {
			return SPRINT;
		}
	}
};

const transformBoardConfiguration = (
	boardConfig: GraphQLBoardConfiguration,
): BoardConfiguration => {
	const isSprintsFeatureEnabled = boardConfig.isSprintsFeatureEnabled === true;
	const isChildIssuePlanningEnabled = boardConfig.isChildIssuePlanningEnabled === true;

	const boardConfigTransformed = {
		...boardConfig,
		isBoardJqlFilteringOutEpics: boardConfig.isBoardJqlFilteringOutEpics === true,
		isSprintsFeatureEnabled,
		isChildIssuePlanningEnabled,
		childIssuePlanningMode: transformChildIssuePlanningMode(boardConfig.childIssuePlanningMode),
		sprints: {
			hash: boardConfig.sprints ? transformSprintConfiguration([...boardConfig.sprints]) : {},
			sequence: boardConfig.sprints.map((sprint: GraphQLSprint) => sprint.id),
		},
	};

	return boardConfigTransformed;
};

const transformConfiguration = (
	roadmapConfiguration: GraphQLRoadmapConfiguration,
): Configuration => {
	const {
		dependencies,
		externalConfiguration,
		rankIssuesSupported,
		isCrossProject,
		boardConfiguration,
		projectConfiguration,
		hierarchyConfiguration,
	} = roadmapConfiguration;

	const { dependenciesEnabled, inwardDependencyDescription, outwardDependencyDescription } =
		dependencies;

	const { colorField, startDateField, rankField, sprintField } = externalConfiguration;

	return {
		today: getToday(),
		rankIssuesSupported,
		isCrossProject,
		startDateCustomFieldId: startDateField,
		lexoRankCustomFieldId: rankField,
		sprintCustomFieldId: sprintField,
		colorCustomFieldId: colorField,
		inwardDependencyDescription: nullableToOptional(inwardDependencyDescription),
		outwardDependencyDescription: nullableToOptional(outwardDependencyDescription),
		dependenciesSupported: dependenciesEnabled,
		projectConfiguration: transformProjectConfig(projectConfiguration),
		boardConfiguration: transformBoardConfiguration(boardConfiguration),
		hierarchyConfiguration: hierarchyConfiguration ?? { levelOneName: '' },
		deriveFields: transformDerivedFieldsHash(boardConfiguration.derivedFields),
		fields: [...(externalConfiguration.fields ?? [])],
		canInviteOthersToProject: false,
	};
};

// NOTE: The only valuable bit of this transformer is to convert the itemTypeId from number to string, we should unify it between BE and FE to remove the transformation
const transformCreationPreference = (
	creationPreferences: GraphQLCreationPreferences,
): CreationPreferences => {
	const itemTypes: ItemTypeHash = {};

	if (creationPreferences && creationPreferences.itemTypes) {
		Object.keys(creationPreferences.itemTypes).forEach((key: string) => {
			const level = Number(key);
			const { itemTypeId } = creationPreferences.itemTypes[level];

			itemTypes[level] = {
				itemTypeId: itemTypeId.toString(),
			};
		});
	}

	return { itemTypes };
};

/**
 * Data transformation for user preferences. User setting is part of critical-data
 * Please follow fast-five principle when make any changes in the types
 *
 * Can't remove the default value in transformUserProperties, the data is required
 * for user-properties engine initialisation.
 */
export const transformUserProperties = (
	setting: GraphQLUserConfiguration | undefined,
): Settings => {
	const defaultSettings: Settings = {
		timelineMode: MONTHS,
		listWidth: DEFAULT_LIST_WIDTH(),
		creationPreferences: {
			itemTypes: {},
		},
		isDependenciesVisible: true,
		isProgressVisible: true,
		isWarningsVisible: true,
		isReleasesVisible: true,
		highlightedVersions: [],
		levelOneViewSettings: DEFAULT_LEVEL_ONE_VIEW_SETTINGS,
		issuePanelRatio: DEFAULT_PANEL_RATIO,
	};

	if (setting) {
		defaultSettings.creationPreferences = transformCreationPreference(setting.creationPreferences);

		const {
			timelineMode = defaultSettings.timelineMode,
			listWidth = defaultSettings.listWidth,
			isDependenciesVisible = defaultSettings.isDependenciesVisible,
			isProgressVisible = defaultSettings.isProgressVisible,
			isWarningsVisible = defaultSettings.isWarningsVisible,
			isReleasesVisible = defaultSettings.isReleasesVisible,
			highlightedVersions = defaultSettings.highlightedVersions,
			levelOneViewSettings = defaultSettings.levelOneViewSettings,
			issuePanelRatio = defaultSettings.issuePanelRatio,
		} = setting;

		return {
			...defaultSettings,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			timelineMode: timelineMode as TimelineMode, // NOTE: enum types genrated from AGG has "%future added value" fallback
			listWidth,
			isDependenciesVisible,
			isProgressVisible,
			isWarningsVisible,
			isReleasesVisible,
			highlightedVersions: [...highlightedVersions], // NOTE: remove readonly marker from types genrated from AGG
			levelOneViewSettings,
			issuePanelRatio: issuePanelRatio || defaultSettings.issuePanelRatio,
		};
	}

	return defaultSettings;
};

export const transformCriticalData = ({
	currentUser,
	roadmapConfiguration,
	items,
	metadata,
	healthcheck,
}: GraphQLCriticalDataResponse): CriticalDataResponse => ({
	currentUser,
	configuration: roadmapConfiguration ? transformConfiguration(roadmapConfiguration) : undefined,
	statusCategories: roadmapConfiguration
		? transformStatusCategories([...roadmapConfiguration.statusCategories])
		: undefined,
	settings: transformUserProperties(roadmapConfiguration?.userConfiguration),
	...transformIssues([...items]),
	metadata: { ...metadata },
	healthcheck,
});
