import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	header: {
		id: 'software-filters.filters.assignee.stateless.unassigned-filter-nudge.header',
		defaultMessage: 'Find available work faster',
		description: 'The header of the unassigned filter nudge.',
	},
	dismissIconLabel: {
		id: 'software-filters.filters.assignee.stateless.unassigned-filter-nudge.dismiss-icon-label',
		defaultMessage: 'Dismiss',
		description: 'The label of the dismiss icon button.',
	},
	turnOnUnassignedFilter: {
		id: 'software-filters.filters.assignee.stateless.unassigned-filter-nudge.turn-on-unassigned-filter',
		defaultMessage: 'Turn on unassigned filter',
		description: 'The text of the button to turn on the unassigned filter.',
	},
	content: {
		id: 'software-filters.filters.assignee.stateless.unassigned-filter-nudge.content',
		defaultMessage: 'Only show unassigned work on the board.',
		description: 'The content of the unassigned filter nudge.',
	},
});
