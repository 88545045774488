/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React, {
	type FocusEvent,
	type KeyboardEvent,
	type KeyboardEventHandler,
	memo,
	type MouseEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';

import { css, jsx } from '@compiled/react';
import noop from 'lodash/noop';
import { useIntl } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import traceUFOPress from '@atlaskit/react-ufo/trace-press';
import Spinner from '@atlaskit/spinner';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import {
	BASE_LEVEL_ITEM_HEIGHT,
	LIST_COLUMN_COORDINATE,
	zIndex,
} from '../../../../common/constants';
import { useIsItemActive, useIsItemSelected } from '../../../../common/context/items';
import { useListWidth } from '../../../../common/context/list';
import { useSideEffectMarshal } from '../../../../common/context/side-effect-marshal';
import { useIsCreateTriggerActive } from '../../../../common/context/side-effect-marshal/focus-marshal';
import { useGridCell } from '../../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell';
import { getRowClassName } from '../../../../common/context/side-effect-marshal/style-marshal/utils';
import { getListContentPaddingLeft } from '../../../../common/styled/list';
import type {
	ItemDropPayload,
	ItemSelectPayload,
	OnCreateClick,
	OnItemExpandChanged,
} from '../../../../common/types/callbacks';
import type { ItemId } from '../../../../common/types/item';
import { fireUIAnalyticsEvent } from '../../../../common/utils/analytics';
import { useItemSelect } from '../../../../common/utils/use-item-select';
import type {
	RenderListItemContent,
	RenderListItemMenuOption,
	RenderListItemModal,
	RenderListItemSecondaryContent,
} from '../../../../renderers';

import CreateChildButton from './create-child-button';
import CreateSiblingButton from './create-sibling-button';
import ExpandButton from './expand-button';
import MeatBallsButton from './meatballs-button';
import messages from './messages';
import SelectCheckbox from './select-checkbox';
import useListItemDrag from './use-drag';

const secondaryContentStyles = css({
	display: 'inline-flex',
	alignItems: 'center',
	marginLeft: token('space.050', '4px'),
});

const listItemContainerStyles = css({
	position: 'sticky',
	left: 0,
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	paddingRight: token('space.100', '8px'),
	transition: 'background-color 100ms linear',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const getCursor = (isInTransition: boolean, isSelectEnabled: boolean) => {
	if (isInTransition) {
		return 'not-allowed';
	}

	if (isSelectEnabled) {
		return 'pointer';
	}

	return 'default';
};

const PADDING_LEFT_INCREMENT = 12;

export type Props = {
	isParent: boolean;
	isExpanded: boolean;
	isDragEnabled: boolean;
	isInTransition: boolean;
	isSelectEnabled: boolean;
	isMultiSelectEnabled: boolean;
	isCreateChildEnabled: boolean;
	isCreateSiblingEnabled: boolean;
	isMeatballsButtonEnabled: boolean;
	id: ItemId;
	parentId: ItemId | undefined;
	level: number;
	depth: number;
	itemHeight: number;
	backgroundColor: string;
	ariaControls?: string;
	renderListItemContent: RenderListItemContent;
	renderListItemSecondaryContent: RenderListItemSecondaryContent;
	// TODO: Remove the undefined type once the create child button deprecated
	renderListItemMenuOption: RenderListItemMenuOption | undefined;
	renderListItemModal: RenderListItemModal | undefined;
	// Remove this with project_timeline_multi-select_and_checkboxes
	onSelect: (id: ItemId, meta: ItemSelectPayload) => unknown;
	onExpandChanged: OnItemExpandChanged;
	onInlineCreateClicked: OnCreateClick;
	onDrop: (id: ItemId, payload: ItemDropPayload) => void;
};

const ListItem = ({
	id,
	parentId,
	level,
	depth,
	itemHeight,
	backgroundColor,
	ariaControls,
	isParent,
	isExpanded,
	isDragEnabled,
	isInTransition,
	isSelectEnabled,
	isMultiSelectEnabled,
	isCreateChildEnabled,
	isCreateSiblingEnabled,
	isMeatballsButtonEnabled,
	onSelect,
	onDrop,
	onExpandChanged,
	onInlineCreateClicked,
	renderListItemContent,
	renderListItemSecondaryContent,
	renderListItemMenuOption,
	renderListItemModal,
}: Props) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();
	const sideEffectMarshal = useSideEffectMarshal();
	const [listWidth] = useListWidth();
	const [isHoveredOrFocused, setIsHoveredOrFocused] = useState(false);
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const isContainerInFocus = useRef<boolean>(false);
	const element = useRef<HTMLElement | null>(null);
	const cellRef = useGridCell(id, LIST_COLUMN_COORDINATE);
	const isFocused = useRef<boolean>(false);

	const [isActive] = fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsItemActive(id)
		: [false];

	/* eslint-disable react-hooks/rules-of-hooks */
	const { selectable, selected, handleClick, handleKeyDown }: ReturnType<typeof useItemSelect> =
		(() => {
			if (fg('project_timeline_multi-select_and_checkboxes')) {
				return useItemSelect(id);
			}

			const [isItemSelected = false] = useIsItemSelected(id);

			return {
				selectable: false,
				selected: isItemSelected,
				handleClick: noop,
				handleKeyDown: noop,
			};
		})();
	/* eslint-enable react-hooks/rules-of-hooks */

	const { onRowMouseEnter, onRowMouseLeave, onRowFocus, onRowBlur } = sideEffectMarshal;

	const [isActiveCreateChildTrigger] = fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
		? // Safe to add this in as hook will either always be run, or never be run based on FG.
			// Do not want to introduce a massive fork to this component.
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useIsCreateTriggerActive({ id, type: 'CHILD' })
		: [false];

	const canDrag = isDragEnabled && !isInTransition;
	const [makeDraggable, cleanupDraggable] = useListItemDrag({
		id,
		level,
		depth,
		parentId,
		canDrag,
		sideEffectMarshal,
	});

	// Effects //

	useEffect(() => {
		makeDraggable(element.current);
		return () => {
			// drag connections will be unsubscribed if NULL was passed to drag handler.
			cleanupDraggable();
		};
	}, [makeDraggable, cleanupDraggable]);

	// Callbacks //

	const onHoverOrFocus = useCallback(() => {
		onRowMouseEnter(id);
		setIsHoveredOrFocused(true);
	}, [id, onRowMouseEnter]);

	const onStopHoverOrFocus = useCallback(() => {
		if (isFocused.current && fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')) {
			onRowBlur(id);
			isFocused.current = false;
		}

		onRowMouseLeave(id);
		setIsMenuOpen(false);
		setIsHoveredOrFocused(false);
	}, [id, onRowMouseLeave, onRowBlur]);

	const onFocus = useCallback(() => {
		onRowFocus(id);
		onHoverOrFocus();
		isFocused.current = true;
	}, [id, onHoverOrFocus, onRowFocus]);

	const onContainerBlurOld = useCallback(
		({ relatedTarget }: FocusEvent<HTMLDivElement>) => {
			// @ts-ignore
			if (!element.current?.contains(relatedTarget) && !isContainerInFocus.current) {
				onStopHoverOrFocus();
			}

			isContainerInFocus.current = false;
		},
		[onStopHoverOrFocus],
	);

	const onContainerBlur = useCallback(
		({ relatedTarget }: FocusEvent<HTMLDivElement>) => {
			// @ts-ignore
			if (!element.current?.contains(relatedTarget)) {
				onStopHoverOrFocus();
			}
		},
		[onStopHoverOrFocus],
	);

	const onContainerClickOld = useCallback(
		(event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
			const { timeStamp, metaKey, ctrlKey, shiftKey } = event;
			traceUFOPress('timeline-item-clicked', timeStamp);

			const withCmd = ctrlKey || metaKey;
			const withShift = shiftKey;

			onSelect(id, {
				level,
				withCmd,
				withShift,
				depth,
			});
		},
		[id, level, depth, onSelect],
	);

	const onContainerClickNew = useCallback(
		(event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLElement>) => {
			const { timeStamp, metaKey, ctrlKey, shiftKey } = event;
			traceUFOPress('timeline-item-clicked', timeStamp);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'listItem',
				actionSubjectId: 'issueListItem',
				attributes: {
					level,
					depth,
					withCmd: ctrlKey || metaKey,
					withShift: shiftKey,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);
			handleClick(event, analyticsEvent, element);
		},
		[createAnalyticsEvent, level, depth, handleClick],
	);

	const onContainerClick = fg('project_timeline_multi-select_and_checkboxes')
		? onContainerClickNew
		: onContainerClickOld;

	const onSelectCheckboxToggle = useCallback(
		(event: MouseEvent<HTMLInputElement>) => {
			const { timeStamp, shiftKey } = event;
			traceUFOPress('timeline-item-select-checkbox-toggled', timeStamp);

			const analyticsEvent = createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'checkbox',
				actionSubjectId: 'issueListItemSelect',
				attributes: {
					level,
					depth,
					withShift: shiftKey,
				},
			});

			fireUIAnalyticsEvent(analyticsEvent);
			handleClick(event, analyticsEvent);
		},
		[createAnalyticsEvent, depth, handleClick, level],
	);

	const onContainerMouseLeave = useCallback(() => {
		if (!element.current?.contains(document.activeElement)) {
			onStopHoverOrFocus();
		}
	}, [onStopHoverOrFocus]);

	const onContainerMouseDown = useCallback(() => {
		isContainerInFocus.current = true;
	}, []);

	let onContainerKeyDown: KeyboardEventHandler<HTMLElement> | undefined;

	if (fg('project_timeline_multi-select_and_checkboxes')) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		onContainerKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
			(event) => {
				// Handle key events on the container only (not bubbled ones)
				if (event.target === event.currentTarget) {
					const analyticsEvent = createAnalyticsEvent({
						action: 'keydown',
						actionSubject: 'listItem',
						actionSubjectId: 'issueListItem',
					});

					handleKeyDown(event, analyticsEvent);
				}
			},
			[createAnalyticsEvent, handleKeyDown],
		);
	}

	const setItemRef = useCallback(
		(ref: HTMLElement | null) => {
			element.current = ref;
			cellRef(ref);
		},
		[cellRef],
	);

	// Render //

	const rendererProps = { id };
	const rendererState = { isVirtual: false, isHoveredOrFocused };

	const renderSecondaryContent = () => {
		if (isInTransition) {
			return (
				<div css={secondaryContentStyles}>
					<Spinner
						size="small"
						testId={`roadmap.timeline-table.components.list-item.spinner.${id}`}
					/>
				</div>
			);
		}

		return (
			<div css={secondaryContentStyles}>
				{renderListItemSecondaryContent(rendererProps, rendererState)}
				{renderListItemMenuOption && isMeatballsButtonEnabled && (
					<>
						{((isCreateChildEnabled && isHoveredOrFocused) || isActiveCreateChildTrigger) && (
							<CreateChildButton
								parentId={id}
								label={formatMessage(messages.createChild)}
								onInlineCreateClicked={onInlineCreateClicked}
								isVisible={!isActiveCreateChildTrigger}
							/>
						)}
						<MeatBallsButton
							isTriggerVisible={isHoveredOrFocused}
							isMenuOpen={isMenuOpen}
							id={id}
							level={level}
							depth={depth}
							parentId={parentId}
							isCreateChildEnabled={isCreateChildEnabled}
							isCreateSiblingEnabled={isCreateSiblingEnabled}
							renderListItemMenuOption={renderListItemMenuOption}
							renderListItemModal={renderListItemModal}
							onStopHoverOrFocus={onStopHoverOrFocus}
							onInlineCreateClicked={onInlineCreateClicked}
							onDrop={onDrop}
							setIsMenuOpen={setIsMenuOpen}
							cellRef={element}
						/>
					</>
				)}
			</div>
		);
	};

	const renderSecondaryContentOld = () => {
		let innerElement;

		if (isInTransition) {
			innerElement = (
				<Spinner
					size="small"
					testId={`roadmap.timeline-table.components.list-item.spinner.${id}`}
				/>
			);
		} else if (isCreateChildEnabled) {
			innerElement = isHoveredOrFocused ? (
				<CreateChildButton
					parentId={id}
					label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.createChildIssueTermRefresh
							: messages.createChild,
					)}
					onInlineCreateClicked={onInlineCreateClicked}
				/>
			) : null;
		}
		// Consumer secondary content should only be visible when internal secondary content is not
		if (innerElement) {
			return <div css={secondaryContentStyles}>{innerElement}</div>;
		}

		return renderListItemSecondaryContent(rendererProps, rendererState);
	};

	const height = depth > 0 ? BASE_LEVEL_ITEM_HEIGHT() : itemHeight;
	const listContentPaddingLeft = getListContentPaddingLeft({
		isParent,
		depth,
		...(fg('project_timeline_multi-select_and_checkboxes') && {
			hasCheckbox: isMultiSelectEnabled,
		}),
		offset:
			!isMultiSelectEnabled && fg('platform-component-visual-refresh')
				? PADDING_LEFT_INCREMENT
				: undefined,
	});

	return (
		<div
			id={id}
			data-testid={`roadmap.timeline-table.components.list-item.container-${id}`}
			role="gridcell"
			tabIndex={-1}
			ref={setItemRef}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={getRowClassName(id, parentId).className}
			css={listItemContainerStyles}
			style={{
				width: `${listWidth}px`,
				height: `${height}px`,
				backgroundColor,
				paddingLeft: `${listContentPaddingLeft}px`,
				zIndex:
					isMenuOpen && fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
						? zIndex.PORTAL // eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
						: zIndex.LIST, // eslint-disable-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				cursor: getCursor(isInTransition, isSelectEnabled),
			}}
			onClick={isSelectEnabled && !isInTransition ? onContainerClick : undefined}
			onKeyDown={onContainerKeyDown}
			onMouseEnter={onHoverOrFocus}
			onMouseLeave={
				fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
					? onContainerMouseLeave
					: onStopHoverOrFocus
			}
			{...(!fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y') && {
				onMouseDown: onContainerMouseDown,
			})}
			onFocus={fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y') ? onFocus : onHoverOrFocus}
			onBlur={
				fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y') ? onContainerBlur : onContainerBlurOld
			}
		>
			{isSelectEnabled &&
			(selected || isActive) &&
			isMenuOpen &&
			fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y') ? (
				// Applies a border overlay to the currently selected item if the meatballs menu is open.
				// This is required as we increase the z-index of this list item to the PORTAL layer to
				// fix overlapping issues in the timeline.
				<Box xcss={borderOverlayStyles} />
			) : null}
			{isMultiSelectEnabled && fg('project_timeline_multi-select_and_checkboxes') && (
				<Box
					xcss={[
						fg('platform-component-visual-refresh')
							? checkboxWrapperStyles
							: checkboxWrapperStylesOld,
					]}
				>
					<SelectCheckbox
						id={id}
						isChecked={selected}
						isDisabled={!selectable}
						onToggle={onSelectCheckboxToggle}
					/>
				</Box>
			)}
			{isParent && (
				<ExpandButton
					id={id}
					label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.expandIssueTermRefresh
							: messages.expand,
					)}
					isExpanded={isExpanded}
					onExpandChanged={onExpandChanged}
					ariaControls={ariaControls}
				/>
			)}
			{renderListItemContent(rendererProps, rendererState)}
			{fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')
				? renderSecondaryContent()
				: renderSecondaryContentOld()}
			{isCreateSiblingEnabled && (
				<CreateSiblingButton
					id={id}
					level={level}
					depth={depth}
					parentId={parentId}
					label={formatMessage(
						fg('jira-issue-terminology-refresh-m3')
							? messages.createSiblingIssueTermRefresh
							: messages.createSibling,
					)}
					onInlineCreateClicked={onInlineCreateClicked}
				/>
			)}
		</div>
	);
};

const borderOverlayStyles = xcss({
	width: '100%',
	height: '100%',
	position: 'absolute',
	top: '0',
	left: '0',
	pointerEvents: 'none',
	borderLeft: `1px solid ${token('color.border')}`,
	borderRight: `2px solid ${token('color.border')}`,
});

ListItem.defaultProps = {
	isInTransition: false,
};

ListItem.displayName = 'ListItem';
ListItem.whyDidYouRender = true;

export default memo<Props>(ListItem);

const checkboxWrapperStylesOld = xcss({
	position: 'absolute',
	left: 'space.0',
	marginLeft: 'space.050',
	marginRight: 'space.050',
});

const checkboxWrapperStyles = xcss({
	position: 'absolute',
	left: 'space.0',
	marginLeft: 'space.150',
});
