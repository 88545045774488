import React from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Skeleton } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const TimelineModeSwitcherSkeleton = () => (
	<Container>
		<Skeleton width="74px" height="32px" />
		<Skeleton width="74px" height="32px" />
		<Skeleton width="74px" height="32px" />
	</Container>
);

export default TimelineModeSwitcherSkeleton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	display: 'flex',
	gap: token('space.050'),
});
