/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import AKSprintIcon from '@atlaskit/icon/core/migration/sprint';
import { token } from '@atlaskit/tokens';

type Size = 'small' | 'medium';
type Props = {
	size: Size;
};

const getIconSize = (iconSize: Size) => (iconSize === 'small' ? 16 : 24);

const wrapperStyles = css({
	marginRight: token('space.025'),
});

const SprintIcon = ({ size }: Props) => {
	return (
		<div
			css={wrapperStyles}
			style={{
				height: `${getIconSize(size)}px`,
				width: `${getIconSize(size)}px`,
			}}
		>
			<AKSprintIcon
				testId="roadmap.timeline-table-kit.common.ui.sprint-icon"
				label=""
				spacing={size === 'medium' ? 'spacious' : 'none'}
				color="currentColor"
				LEGACY_size={size}
			/>
		</div>
	);
};

SprintIcon.defaultProps = {
	size: 'small',
};

export { SprintIcon };
