import React from 'react';
import { styled } from '@compiled/react';
import MinusIcon from '@atlaskit/icon/core/minus';
import CheckMarkIcon from '@atlaskit/icon/utility/check-mark';
import { B400, G400, R400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type ReleaseStatusType = 'released' | 'unreleased' | 'overdue' | 'overdue-arj';

interface Props {
	type: ReleaseStatusType;
}

const getIconColor = (type: ReleaseStatusType) => {
	switch (type) {
		case 'released':
			return token('color.icon.success', G400);
		case 'unreleased':
			return token('color.icon.information', B400);
		case 'overdue':
		case 'overdue-arj':
		default:
			return token('color.icon.danger', R400);
	}
};

const getIconContents = (type: ReleaseStatusType) => {
	switch (type) {
		case 'released':
			return (
				<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M11.707 6.707a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4Z"
						fill={token('color.text.inverse')}
					/>
				</svg>
			);
		case 'overdue':
			return (
				<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7 4a1 1 0 0 1 2 0v4a1 1 0 0 1-2 0V4Zm1 8a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
						fill={token('color.text.inverse')}
					/>
				</svg>
			);
		case 'overdue-arj':
			return (
				<svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="4" y="7" width="8" height="2" rx="1" fill={token('color.text.inverse')} />
				</svg>
			);
		case 'unreleased':
		default:
			return undefined;
	}
};

const ReleaseStatusIconOld = ({ type }: Props) => (
	<Container backgroundColor={getIconColor(type)}>{getIconContents(type)}</Container>
);

const ReleaseStatusIcon = ({ type }: Props) => {
	switch (type) {
		case 'released':
			return (
				<Container backgroundColor={getIconColor(type)}>
					<CheckMarkIcon label="" color="currentColor" />
				</Container>
			);
		case 'overdue':
			return (
				<svg
					width="16"
					height="16"
					viewBox="0 0 16 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M0.0175781 8C0.0175478 12.4085 3.59133 15.9823 7.99981 15.9823C12.4083 15.9823 15.9821 12.4085 15.982 8C15.982 3.59155 12.4083 0.0178225 7.99981 0.0178223C3.59136 0.017822 0.0176084 3.59155 0.0175781 8ZM7.24981 9V3.75H8.74981V9H7.24981ZM7.99981 12.25C8.5521 12.25 8.99981 11.8023 8.99981 11.25C8.99981 10.6977 8.5521 10.25 7.99981 10.25C7.44753 10.25 6.99981 10.6977 6.99981 11.25C6.99981 11.8023 7.44753 12.25 7.99981 12.25Z"
						fill={getIconColor(type)}
					/>
				</svg>
			);
		case 'overdue-arj':
			return (
				<Container backgroundColor={getIconColor(type)}>
					<MinusIcon label="" color="currentColor" />
				</Container>
			);
		case 'unreleased':
			return <Container backgroundColor={getIconColor(type)} />;
		default:
			return undefined;
	}
};

export default componentWithCondition(
	() => isVisualRefreshEnabled(),
	ReleaseStatusIcon,
	ReleaseStatusIconOld,
);

interface ContainerProps {
	backgroundColor: string;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.span<ContainerProps>({
	color: token('color.text.inverse'),
	// eslint-disable-next-line @typescript-eslint/no-explicit-any, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props: any) => props.backgroundColor,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: gridSize * 2,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: gridSize * 2,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	borderRadius: '100%',
	boxSizing: 'border-box',
	overflow: 'hidden',
});
