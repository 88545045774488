import React, { createContext, useContext, type ReactNode } from 'react';

const IsProjectThemedContext = createContext<boolean>(false);

export const ProjectThemeProvider = ({
	children,
	isThemed,
}: {
	children: ReactNode;
	isThemed: boolean;
}) => {
	return (
		<IsProjectThemedContext.Provider value={isThemed}>{children}</IsProjectThemedContext.Provider>
	);
};

export const useIsProjectThemed = () => useContext(IsProjectThemedContext);
