import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	moreActionsMenuLabel: {
		id: 'roadmap.standard-roadmap.header.more-settings.more-actions-menu-label',
		defaultMessage: 'More actions',
		description: 'Dropdown menu to access more actions',
	},
	moreSettingsMenuLabel: {
		id: 'roadmap.standard-roadmap.header.more-settings.more-settings-menu-label',
		defaultMessage: 'More',
		description: 'Dropdown menu to access more settings',
	},

	moreSettingsButtonLabel: {
		id: 'roadmap.standard-roadmap.header.more-settings.more-settings-button-label',
		defaultMessage: 'Configure Timeline',
		description: 'Dropdown item to configure the timeline',
	},
	feedbackButtonLabel: {
		defaultMessage: 'Give feedback',
		id: 'roadmap.standard-roadmap.header.more-settings.feedback-button-label',
		description: 'Text of the button that allows users to give feedback',
	},
	feedbackCollectorTitle: {
		id: 'roadmap.standard-roadmap.header.more-settings.feedback-collector-title',
		defaultMessage: 'Give feedback to Atlassian',
		description: 'Title for a feedback dialog',
	},
	feedbackCollectorDescription: {
		id: 'roadmap.standard-roadmap.header.more-settings.feedback-collector-description',
		defaultMessage: 'Select Jira Timeline feedback type',
		description: 'Description for a feedback dialog',
	},
	enterFullScreen: {
		id: 'roadmap.standard-roadmap.header.more-settings.enter-full-screen',
		defaultMessage: 'Enter full screen',
		description: 'Tooltip content for enter full screen button',
	},
	exitFullScreen: {
		id: 'roadmap.standard-roadmap.header.more-settings.exit-full-screen',
		defaultMessage: 'Exit full screen',
		description: 'Tooltip content for exit full screen button',
	},
	exportMenuLabel: {
		id: 'roadmap.standard-roadmap.header.more-settings.export-menu-label',
		defaultMessage: 'Export',
		description: 'Dropdown menu to access export options',
	},
	exportImagePng: {
		id: 'roadmap.standard-roadmap.header.more-settings.export-image-png',
		defaultMessage: 'Image (.png)',
		description: 'Button. Allows a user to export Timeline into a image in PNG format.',
	},
	exportSpreadsheetCsv: {
		id: 'roadmap.standard-roadmap.header.more-settings.export-spreadsheet-csv',
		defaultMessage: 'Spreadsheet (.csv)',
		description: 'Button. Allows a user to export Timeline into a spreadsheet in CSV format.',
	},
	exportSpreadsheetInferredStartDate: {
		id: 'roadmap.standard-roadmap.header.more-settings.export-spreadsheet-inferred-start-date',
		defaultMessage: 'Inferred start date',
		description: 'Column header for the inferred start date in the spreadsheet export',
	},
	exportSpreadsheetInferredDueDate: {
		id: 'roadmap.standard-roadmap.header.more-settings.export-spreadsheet-inferred-due-date',
		defaultMessage: 'Inferred due date',
		description: 'Column header for the inferred due date in the spreadsheet export',
	},
});
