import type { Action } from '@atlassian/react-sweet-state';
import type { State, HasSeenItKey } from '../../types.tsx';
import { performSetHasSeenRequest } from './utils.tsx';

export const setHasSeen =
	(hasSeenKey: HasSeenItKey): Action<State> =>
	async ({ setState }) => {
		setState({
			[hasSeenKey]: { fetchStatus: 'fetched', value: true },
		});

		await performSetHasSeenRequest(hasSeenKey);
	};
