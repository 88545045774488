import type { Action } from 'react-sweet-state';

import type { ItemId } from '../../../types/item';

import { LARGE_STEP } from './constants';
import type {
	AcceptedInputs,
	ContainerProps,
	CreateTriggerType,
	GetContent,
	GetData,
	Modifiers,
	State,
} from './types';

// ======================= //
// === PRIVATE ACTIONS === //
// ======================= //

// Moves the roving tab index, updating the DOM and relevant state
const rove =
	(current: HTMLElement | undefined, next: HTMLElement): Action<State, ContainerProps> =>
	({ setState }) => {
		current?.setAttribute('tabindex', '-1');
		next.setAttribute('tabindex', '0');
		setState({ rovingCell: next });
	};

/* The grid must have a focusable cell to navigate. So when a cell is removed, we check
 * whether doing so would invalidate this requirement and find a suitable replacement.
 */
const reconcile =
	(previous: HTMLElement): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { gridCells, rovingCell, focusedCell } = getState();

		// Does the cell we're removing contain the current roving tab index
		if (previous === rovingCell) {
			const nextCell = gridCells.getStart(rovingCell, true);

			if (nextCell) {
				// Make the new roving point the origin for simplicity
				dispatch(rove(undefined, nextCell));
				// Only focus new cell if the previous was focused too
				previous === focusedCell && nextCell.focus();
			}
		}
	};

// Optionally sets focus to the cell or its content depending on the change in navigation state
const toggleNavigation =
	(cell: HTMLElement, shouldEnable: boolean, autofocus: boolean): Action<State, ContainerProps> =>
	({ getState, setState }) => {
		const { gridCells } = getState();

		const cellContent = gridCells.get(cell)?.getContent();
		if (!cellContent) {
			return;
		}

		if (shouldEnable) {
			setState({ isNavigationEnabled: true });
			autofocus && cell.focus();
		} else if (!shouldEnable && cellContent.length > 0) {
			setState({ isNavigationEnabled: false });
			autofocus && cellContent[0].focus();
		}
	};

// ====================== //
// === PUBLIC ACTIONS === //
// ====================== //

const enableNavigation =
	({ autofocus } = { autofocus: true }): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { rovingCell, isNavigationEnabled } = getState();

		if (!isNavigationEnabled && rovingCell) {
			dispatch(toggleNavigation(rovingCell, true, autofocus));
		}
	};

const disableNavigation =
	({ autofocus } = { autofocus: true }): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { focusedCell, isNavigationEnabled } = getState();

		if (isNavigationEnabled && focusedCell) {
			dispatch(toggleNavigation(focusedCell, false, autofocus));
		}
	};

const initialiseRovingCell =
	(elem: HTMLElement): Action<State, ContainerProps> =>
	({ getState, setState }) => {
		if (!getState().rovingCell) {
			setState({ rovingCell: elem });
		}
	};

const createGetCoordinates =
	(getData: GetData, { getCoordinates }: ContainerProps) =>
	(): [number, number] =>
		getCoordinates(getData);

const trackCell =
	(elem: HTMLElement, getData: GetData, getContent: GetContent): Action<State, ContainerProps> =>
	({ getState }, containerProps) => {
		const getCoordinates = createGetCoordinates(getData, containerProps);
		getState().gridCells.set(elem, { getCoordinates, getContent });
	};

const untrackCell =
	(elem: HTMLElement): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { gridCells } = getState();
		gridCells.delete(elem);
		dispatch(reconcile(elem));
	};

/* Cells can be focused outside our action controlled navigation, such as
 * with a mouse click or tabbing, so we have to be resilient to migrating the cell state
 */
const focusCell =
	(cell: HTMLElement, focusing: HTMLElement): Action<State, ContainerProps> =>
	({ getState, setState, dispatch }) => {
		const { gridCells, rovingCell, isNavigationEnabled } = getState();

		// We're in cell navigation mode and element being focused is a cell
		if (isNavigationEnabled && gridCells.has(focusing)) {
			setState({ focusedCell: focusing });

			if (focusing !== rovingCell) {
				dispatch(rove(rovingCell, focusing));
			}
		} else if (isNavigationEnabled && cell.contains(focusing)) {
			/* We're in cell navigation mode, but the cell content is being focused.
			 * But, DON'T disable cell navigation here because it will create a focus trap
			 * for mouse users. We will check this elsewhere based on keyboard input.
			 */
			setState({ focusedCell: cell });

			if (cell !== rovingCell) {
				dispatch(rove(rovingCell, cell));
			}
		} else if (!isNavigationEnabled && rovingCell) {
			// We're in content navigation mode and we need to trap the focus
			const cellContent = gridCells.get(rovingCell)?.getContent();
			const isContentToFocus = cellContent && cellContent.length > 0;

			if (isContentToFocus && focusing === rovingCell) {
				cellContent[cellContent.length - 1].focus();
			} else if (isContentToFocus && !rovingCell.contains(focusing)) {
				cellContent[0].focus();
			}
		}
	};

const blurCell =
	(blurring: HTMLElement): Action<State, ContainerProps> =>
	({ getState, setState }) => {
		const { gridCells, focusedCell } = getState();

		if (gridCells.has(blurring) && focusedCell === blurring) {
			setState({ focusedCell: undefined });
		}
	};

const navigateCell =
	(type: AcceptedInputs, { ctrlKey }: Modifiers): Action<State, ContainerProps> =>
	({ getState, dispatch }) => {
		const { isNavigationEnabled, gridCells, focusedCell } = getState();

		if (!isNavigationEnabled || !focusedCell) {
			return;
		}

		let nextCell;

		switch (type) {
			case 'ArrowLeft':
				nextCell = gridCells.getBefore(focusedCell);
				break;
			case 'ArrowRight':
				nextCell = gridCells.getAfter(focusedCell);
				break;
			case 'ArrowUp':
				nextCell = gridCells.getAbove(focusedCell);
				break;
			case 'ArrowDown':
				nextCell = gridCells.getBelow(focusedCell);
				break;
			case 'PageUp':
				nextCell = gridCells.getAbove(focusedCell, LARGE_STEP);
				break;
			case 'PageDown':
				nextCell = gridCells.getBelow(focusedCell, LARGE_STEP);
				break;
			case 'Home':
				nextCell = gridCells.getStart(focusedCell, ctrlKey);
				break;
			case 'End':
				nextCell = gridCells.getEnd(focusedCell, ctrlKey);
				break;
			default:
				break;
		}

		if (nextCell && nextCell !== focusedCell) {
			dispatch(rove(focusedCell, nextCell));
			nextCell.focus();
		}
	};

const setActiveCreateTrigger =
	(rowId: ItemId, type: CreateTriggerType, element: HTMLElement): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({
			// Make sure there are no focus traps for when the create input appears
			isNavigationEnabled: true,
			activeCreateTrigger: { rowId, type, element },
		});
	};

const resetRovingCell =
	(): Action<State, ContainerProps> =>
	({ getState, setState, dispatch }) => {
		const { gridCells, rovingCell } = getState();

		if (rovingCell) {
			const origin = gridCells.getClosestStartOrEnd(rovingCell);
			if (origin) {
				setState({ isNavigationEnabled: true });
				dispatch(rove(rovingCell, origin));
			}
		}
	};

// ======================== //
// === CONSUMER ACTIONS === //
// ======================== //

const removeActiveCreateTrigger =
	(): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({
			activeCreateTrigger: undefined,
		});
	};

const focusCreateTrigger =
	(): Action<State, ContainerProps> =>
	({ getState, setState }) => {
		const activeCreateTrigger = getState().activeCreateTrigger;

		if (activeCreateTrigger) {
			// Update the DOM first, otherwise the focus event will be treated as content navigation
			activeCreateTrigger.element?.focus();
			// Make sure to re-enable the focus trap when focus returns to a cell via its create trigger
			setState({ isNavigationEnabled: false, activeCreateTrigger: undefined });
		}
	};

const shouldPreventNavigation =
	(isNavigationPrevented: boolean): Action<State, ContainerProps> =>
	({ setState }) => {
		setState({
			isNavigationPrevented,
		});
	};

export const actions = {
	enableNavigation,
	disableNavigation,
	shouldPreventNavigation,
	initialiseRovingCell,
	resetRovingCell,
	trackCell,
	untrackCell,
	focusCell,
	blurCell,
	navigateCell,
	setActiveCreateTrigger,
	focusCreateTrigger,
	removeActiveCreateTrigger,
};
