import { useFlagsService } from '@atlassian/jira-flags';
import { toFlagId } from '@atlassian/jira-flags/src/services/types';
import message from './messages.tsx';

export const CommerceApiErrorFlag = () => {
	const flagKey = toFlagId('commerce-api-error');
	const { showFlag } = useFlagsService();

	showFlag({
		id: flagKey,
		key: flagKey,
		type: 'error',
		title: message.title,
		description: message.description,
		isAutoDismiss: true,
		messageId: 'up-flow-iframe.common.ui.commerce-api-error-flag.showFlag.error',
		messageType: 'transactional',
	});
	return null;
};
