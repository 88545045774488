/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { type ReactNode } from 'react';

import { css, jsx } from '@compiled/react';

import { fg } from '@atlaskit/platform-feature-flags';
import { B100 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { CHART_COLUMN_COORDINATE, DEFAULT_HEADER_ROW_ID } from '../../../../../../common/constants';
import { useGridCell } from '../../../../../../common/context/side-effect-marshal/focus-marshal/use-grid-cell';

const wrapperStyles = css({
	position: 'absolute',
	top: token('space.0', '0'),
	bottom: token('space.0', '0'),
	flex: '0 0 auto',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.050', '4px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.050', '4px'),
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

const wrapperStylesOld = css({
	position: 'absolute',
	top: token('space.0', '0'),
	bottom: token('space.0', '0'),
	flex: '0 0 auto',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	'&:focus': {
		outline: 'none',
	},
	'&:focus-visible': {
		boxShadow: `inset 0 0 0 2px ${token('color.border.focused', B100)}`,
	},
});

type Props = {
	left: number;
	right: number;
	column: number;
	children: ReactNode;
	label?: string;
};

export const CalendarCellWrapper = ({ left, right, column, children, label }: Props) => {
	const cellRef = useGridCell(DEFAULT_HEADER_ROW_ID, CHART_COLUMN_COORDINATE + column);

	return (
		<div
			ref={cellRef}
			role="columnheader"
			tabIndex={-1}
			css={[
				// eslint-disable-next-line @atlaskit/platform/no-preconditioning, @atlaskit/platform/ensure-feature-flag-prefix
				fg('platform-component-visual-refresh') && fg('visual-refresh_drop_4')
					? wrapperStyles
					: wrapperStylesOld,
			]}
			style={{
				left: `${left}%`,
				right: `${right}%`,
			}}
			aria-label={fg('jsw_roadmaps_timeline-fix-a11y-rain') ? label : undefined}
		>
			{children}
		</div>
	);
};
