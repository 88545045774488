/** @jsx jsx */
import React, { forwardRef, useEffect, useRef } from 'react';
import { styled, jsx } from '@compiled/react';
import Badge from '@atlaskit/badge';
import Button from '@atlaskit/button';
import ChevronDownIconOld from '@atlaskit/icon/glyph/chevron-down';
import ChevronRightIconOld from '@atlaskit/icon/glyph/chevron-right';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import Tooltip from '@atlaskit/tooltip';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { SCREEN, ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';

import { useIsProjectThemed } from '../../../../controller/project-theme-provider/ProjectThemeProvider.tsx';

type PopupProps = {
	isOpen: boolean;
	'aria-haspopup': 'true';
	'aria-expanded': boolean;
	'aria-controls'?: string;
};

type Props = {
	isNested: boolean;
	label: string;
	isDisabled?: boolean;
	buttonType?: string;
	selectedCount: number;
	onMouseEnter: () => void;
	shouldReturnFocus?: boolean;
} & PopupProps;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Ref = React.Ref<any>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FilterListTrigger = forwardRef<any, Props>(
	(
		{
			isOpen,
			isNested,
			isDisabled,
			label,
			buttonType,
			selectedCount,
			onMouseEnter,
			shouldReturnFocus,
			...popupProps
		}: Props,
		ref: Ref,
	) => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const isThemed = getIsSoftwareThemingEnabled() && useIsProjectThemed();
		const testId = `filters.common.ui.list.${label.toLowerCase().replace(/ /g, '-')}-filter`;

		const primaryColor = isOpen ? token('color.icon.selected', '#0052CC') : token('color.icon');

		const IconComponent = isNested ? (
			<ChevronRightIcon
				label=""
				LEGACY_fallbackIcon={ChevronRightIconOld}
				LEGACY_primaryColor={primaryColor}
				{...(!isThemed && {
					color: primaryColor,
				})}
			/>
		) : (
			<ChevronDownIcon
				label=""
				LEGACY_fallbackIcon={ChevronDownIconOld}
				LEGACY_primaryColor={primaryColor}
				{...(!isThemed && {
					color: primaryColor,
				})}
			/>
		);

		const inputRef = useRef<HTMLButtonElement>();
		const isMounted = useRef(false);
		useEffect(() => {
			if (shouldReturnFocus && buttonType === 'QuickFilters' && isMounted.current) {
				inputRef?.current?.focus();
			} else {
				isMounted.current = true;
			}
		}, [shouldReturnFocus, buttonType]);

		const conditionalRef = buttonType === 'QuickFilters' ? mergeRefs(ref, inputRef) : ref;

		// eslint-disable-next-line no-nested-ternary
		const ButtonComponent = isThemed
			? ThemedButton
			: getIsSoftwareThemingEnabled()
				? Button
				: StyledButton;

		return (
			<Box xcss={[!isNested && containerStyles]}>
				<ContextualAnalyticsData
					sourceName="dropDownMenu"
					sourceType={SCREEN}
					attributes={{ filterType: label }}
				>
					<ButtonComponent
						{...popupProps}
						ref={conditionalRef}
						isSelected={isOpen || (isVisualRefreshEnabled() && selectedCount > 0)}
						shouldFitContainer={isNested}
						appearance={isVisualRefreshEnabled() && !isNested ? 'default' : 'subtle'}
						onMouseEnter={onMouseEnter}
						iconAfter={isVisualRefreshEnabled() && selectedCount > 0 ? undefined : IconComponent}
						isDisabled={isDisabled || false}
						{...(getIsSoftwareThemingEnabled() &&
							isNested && {
								css: {
									borderRadius: '0 !important',
									paddingRight: `${token('space.050')} !important`,
									'&:focus': {
										outlineOffset: '-2px',
									},
								},
							})}
						isNested={isNested} // TODO: remove this prop on getIsSoftwareThemingEnabled cleanup
					>
						<Tooltip content={label} hideTooltipOnClick tag="span">
							<TriggerContainer data-testid={testId}>
								<TriggerLabel>{label}</TriggerLabel>
								{selectedCount > 0 && (
									<CountWrapper isNested={isNested} data-testid={`${testId}-badge`}>
										<Badge appearance="primary">{selectedCount}</Badge>
									</CountWrapper>
								)}
							</TriggerContainer>
						</Tooltip>
					</ButtonComponent>
				</ContextualAnalyticsData>
			</Box>
		);
	},
);

export default FilterListTrigger;

const containerStyles = xcss({
	paddingLeft: 'space.050',
	paddingRight: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const StyledButton = styled(Button)<{ isNested: boolean }>(({ isNested }) =>
	isNested
		? `
            border-radius: 0 !important;
			${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
            padding-right: ${gridSize / 2}px !important;
            &:focus {
                outline-offset: -2px;
            }
    `
		: '',
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CountWrapper = styled.span<{ isNested: boolean }>(
	{
		display: 'inline-block',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isNested }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isNested ? `margin-left: auto; padding-left: ${gridSize}px` : `padding-left: ${gridSize / 2}px`,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerContainer = styled.span({
	display: 'flex',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerLabel = styled.span({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxWidth: `${gridSize * 15}px`,
	whiteSpace: 'nowrap',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});
