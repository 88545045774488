import React, { type ReactNode, useCallback, useEffect, useRef } from 'react';

import {
	createActionsHook,
	createContainer,
	createHook,
	createStateHook,
	createStore,
} from 'react-sweet-state';

import { useHeaderItemIndexes } from '../../header';
import { useItemIndexes } from '../../items';

import { actions } from './actions';
import { CellMap } from './cell-map';
import {
	isCreateTriggerActiveForItem,
	isNavigationEnabled,
	isNavigationPrevented,
} from './selectors';
import type { ContainerProps, GetData, State } from './types';

const initialState: State = {
	gridCells: new CellMap(),
	focusedCell: undefined,
	activeCreateTrigger: undefined,
	rovingCell: undefined,
	isNavigationEnabled: true,
	isNavigationPrevented: false,
};

const focusStore = createStore<State, typeof actions>({
	initialState,
	actions,
	name: 'timeline-table.focus',
});

const FocusContainer = createContainer<State, typeof actions, ContainerProps>(focusStore);

const useFocusMarshal = createActionsHook(focusStore);

const useFocusState = createStateHook(focusStore);

const useIsNavigationEnabled = createStateHook(focusStore, {
	selector: isNavigationEnabled,
});

const useIsNavigationPrevented = createStateHook(focusStore, { selector: isNavigationPrevented });

const useIsCreateTriggerActive = createHook(focusStore, {
	selector: isCreateTriggerActiveForItem,
});

function usePreviousValue<T>(value: T): T | undefined {
	// Create a ref to store the previous value
	const ref = useRef<T>();
	// Update the ref with the current value after each render
	useEffect(() => {
		ref.current = value;
	}, [value]);
	// Return the previous value (before the current render)
	return ref.current;
}

const FocusMarshalProvider = ({ children }: { children: ReactNode }) => {
	const focusState = useFocusState();

	const [headerIndexesUnstable] = useHeaderItemIndexes();
	const [itemIndexesUnstable] = useItemIndexes();

	const previousHeaderIndexUnstable = usePreviousValue(headerIndexesUnstable);
	const previousItemIndexUnstable = usePreviousValue(itemIndexesUnstable);

	/* This follows the "useEventCallback" pattern. We want the callback reference to remain stable,
	 * but the internal values to also remain up-to-date. In doing so we can lazily evaluate coordinates
	 * using the action thunk and not have to hold a copy of the indexes elsewhere that is kept in sync.
	 */
	const headerIndexes = useRef(headerIndexesUnstable);
	const itemIndexes = useRef(itemIndexesUnstable);

	useEffect(() => {
		headerIndexes.current = headerIndexesUnstable;
		itemIndexes.current = itemIndexesUnstable;
	});

	useEffect(() => {
		if (
			headerIndexesUnstable !== previousHeaderIndexUnstable ||
			itemIndexesUnstable !== previousItemIndexUnstable
		) {
			const cellMap = focusState.gridCells;
			cellMap.setIsDirty(true);
		}
	}, [
		focusState.gridCells,
		headerIndexesUnstable,
		itemIndexesUnstable,
		previousHeaderIndexUnstable,
		previousItemIndexUnstable,
	]);

	const getCoordinates = useCallback((getRowData: GetData): [number, number] => {
		const { column, rowId } = getRowData();

		const itemIndexOffset = Object.keys(headerIndexes.current).length;
		const row = headerIndexes.current[rowId] ?? itemIndexes.current[rowId] + itemIndexOffset;

		return [column, row];
	}, []);

	return (
		<FocusContainer
			getCoordinates={getCoordinates}
			headerIndexes={headerIndexes.current}
			itemIndexes={itemIndexes.current}
		>
			{children}
		</FocusContainer>
	);
};

export {
	useFocusState,
	useFocusMarshal,
	useIsNavigationEnabled,
	useIsNavigationPrevented,
	useIsCreateTriggerActive,
	FocusMarshalProvider,
};
