import React, { useCallback, type RefObject } from 'react';
import { IconButton } from '@atlaskit/button/new';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import UploadIcon from '@atlaskit/icon/core/migration/upload--export';
import Tooltip from '@atlaskit/tooltip';
import { ButtonItem, Section } from '@atlaskit/menu';
import { Box, Stack, xcss } from '@atlaskit/primitives';
import { fg } from '@atlassian/jira-feature-gating';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ResponsiveThemedButton } from '@atlassian/jira-project-theme-components/src/ui/responsive-themed-button/ResponsiveThemedButton.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { ExportCsvActionPayload } from '../../../common/types/export-action.tsx';
import messages from './messages.tsx';

type Props = {
	onExportCsv: (payload: ExportCsvActionPayload) => void;
	onExportAsImageClick?: () => void;
	/* Indicates whether the button should be rendered with a themed appearance,
	specifically for views used in Business projects with background styling */
	isThemedButton?: boolean;
	exportButtonRef?: RefObject<HTMLElement>;
	isPopup?: boolean;
};

const ExportButton = ({
	onExportCsv,
	onExportAsImageClick,
	isThemedButton,
	exportButtonRef,
	isPopup,
}: Props) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onExportCsvCallback = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({});
		fireUIAnalytics(analyticsEvent, 'exportCsv clicked');
		onExportCsv({
			inferredStartDateHeader: formatMessage(messages.exportSpreadsheetInferredStartDate),
			inferredDueDateHeader: formatMessage(messages.exportSpreadsheetInferredDueDate),
		});
	}, [createAnalyticsEvent, onExportCsv, formatMessage]);

	if (isPopup && isVisualRefreshEnabled() && fg('visual-refresh_drop_6')) {
		return (
			<Box onClick={(e: React.MouseEvent) => e.stopPropagation()}>
				<Stack xcss={nestedPopupStyles}>
					<Section testId="roadmap.timeline-table-kit.ui.header.export.popup-content">
						<ButtonItem onClick={onExportAsImageClick}>
							{formatMessage(messages.exportImagePng)}
						</ButtonItem>
						<ButtonItem onClick={onExportCsvCallback}>
							{formatMessage(messages.exportSpreadsheetCsv)}
						</ButtonItem>
					</Section>
				</Stack>
			</Box>
		);
	}

	return (
		<DropdownMenu
			trigger={({ triggerRef, ...props }) =>
				// eslint-disable-next-line no-nested-ternary
				isVisualRefreshEnabled() ? (
					// eslint-disable-next-line no-nested-ternary
					isThemedButton ? (
						fg('visual-refresh_drop_4') ? (
							<Tooltip content={formatMessage(messages.exportButton)}>
								<ThemedButton
									aria-label={formatMessage(messages.exportButton)}
									label={formatMessage(messages.exportButton)}
									iconBefore={<UploadIcon label="" color="currentColor" spacing="spacious" />}
									ref={exportButtonRef ? mergeRefs(triggerRef, exportButtonRef) : triggerRef}
									{...props}
									testId="roadmap.timeline-table-kit.ui.header.export.export-button"
								/>
							</Tooltip>
						) : (
							<ThemedButton
								aria-label={formatMessage(messages.exportButton)}
								label={formatMessage(messages.exportButton)}
								iconBefore={<UploadIcon label="" color="currentColor" spacing="spacious" />}
								ref={exportButtonRef ? mergeRefs(triggerRef, exportButtonRef) : triggerRef}
								{...props}
								testId="roadmap.timeline-table-kit.ui.header.export.export-button"
							/>
						)
					) : (
						<IconButton
							label={formatMessage(messages.exportButton)}
							icon={UploadIcon}
							ref={exportButtonRef ? mergeRefs(triggerRef, exportButtonRef) : triggerRef}
							isTooltipDisabled={false}
							{...props}
							testId="roadmap.timeline-table-kit.ui.header.export.export-button"
						/>
					)
				) : isThemedButton && fg('jwm_timeline_export_button_fix') ? (
					<ResponsiveThemedButton
						label={formatMessage(messages.exportButton)}
						icon={<UploadIcon label="" color="currentColor" spacing="spacious" />}
						ref={exportButtonRef ? mergeRefs(triggerRef, exportButtonRef) : triggerRef}
						{...props}
					/>
				) : (
					<ResponsiveButton
						label={formatMessage(messages.exportButton)}
						icon={UploadIcon}
						ref={triggerRef}
						{...props}
					/>
				)
			}
		>
			<DropdownItemGroup>
				<DropdownItem onClick={onExportAsImageClick}>
					{formatMessage(messages.exportImagePng)}
				</DropdownItem>
				<DropdownItem onClick={onExportCsvCallback}>
					{formatMessage(messages.exportSpreadsheetCsv)}
				</DropdownItem>
			</DropdownItemGroup>
		</DropdownMenu>
	);
};

export { ExportButton };

const nestedPopupStyles = xcss({
	maxWidth: '800px',
});
