import { useFlagsService } from '@atlassian/jira-flags';
import { toFlagId } from '@atlassian/jira-flags/src/services/types';
import { isLoaderError } from '@atlassian/react-loosely-lazy';
import message from './messages.tsx';

export const ChunkLoadErrorFlag = ({ error }: { error: Error }) => {
	const flagKey = toFlagId('chunk-load-error');
	const { showFlag, dismissFlag } = useFlagsService();

	if (isLoaderError(error)) {
		showFlag({
			id: flagKey,
			key: flagKey,
			type: 'error',
			title: message.title,
			description: message.description,
			isAutoDismiss: true,
			messageId: 'up-flow-iframe.ui.chunk-load-error-flag.showFlag.error',
			messageType: 'transactional',
			actions: [
				{
					content: message.refreshPageButton,
					onClick: () => {
						dismissFlag(flagKey);

						// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
						window.location.reload();
					},
				},
			],
		});
	}

	return null;
};
