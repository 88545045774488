import React from 'react';

import type { ListItemMenuOptionProps } from '@atlassian/timeline-table/renderers';
import {
	REMOVE_DATES,
	EDIT_SPRINT,
	EDIT_DATES,
	BULK_EDIT_DATES,
	EDIT_DEPENDENCY,
	CHANGE_PARENT_ISSUE,
	CHANGE_ISSUE_COLOR,
} from '../../../../constants.tsx';
import BulkEditDatesOption from './bulk-edit-dates-option/index.tsx';
import ChangeColorOption from './change-color-option/index.tsx';
import EditDatesOption from './edit-dates-option/index.tsx';
import EditDependencyOption from './edit-dependency-option/index.tsx';
import EditSprintOption from './edit-sprint-option/index.tsx';
import RemoveDatesOption from './remove-dates-option/index.tsx';
import ReparentMenuOption from './reparent-menu-option/index.tsx';

const ListItemMenuOption = ({
	optionId,
	id,
	setOpenModal,
	Option,
	setCloseMenu,
	setInitialFocusRef,
}: ListItemMenuOptionProps) => {
	switch (optionId) {
		case CHANGE_PARENT_ISSUE:
			return <ReparentMenuOption id={id} Option={Option} setCloseMenu={setCloseMenu} />;

		case CHANGE_ISSUE_COLOR:
			return <ChangeColorOption id={id} Option={Option} setCloseMenu={setCloseMenu} />;

		case EDIT_SPRINT:
			return <EditSprintOption id={id} Option={Option} setCloseMenu={setCloseMenu} />;

		case EDIT_DATES:
			return <EditDatesOption id={id} setOpenModal={setOpenModal} Option={Option} />;

		case BULK_EDIT_DATES:
			return (
				<BulkEditDatesOption
					id={id}
					setOpenModal={setOpenModal}
					Option={Option}
					setInitialFocusRef={setInitialFocusRef}
				/>
			);

		case REMOVE_DATES:
			return <RemoveDatesOption id={id} Option={Option} setCloseMenu={setCloseMenu} />;

		case EDIT_DEPENDENCY:
			return <EditDependencyOption id={id} setOpenModal={setOpenModal} Option={Option} />;

		default:
			return null;
	}
};

export default ListItemMenuOption;
