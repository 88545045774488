/**
 * @generated SignedSource<<312d05a75db2a1d68f0c5eb65081bf77>>
 * @relayHash 6866f659fb332672f91e6c776b563bbe
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2f62fab508c4c1d6c73becead73b39862c23b2280917c3e51a62e682652e8915

import type { ConcreteRequest, Query } from 'relay-runtime';
export type RoadmapChildIssuePlanningMode = "DATE" | "DISABLED" | "SPRINT" | "%future added value";
export type RoadmapPaletteColor = "BLUE" | "DARK_BLUE" | "DARK_GREEN" | "DARK_GREY" | "DARK_ORANGE" | "DARK_PURPLE" | "DARK_TEAL" | "DARK_YELLOW" | "GREEN" | "GREY" | "ORANGE" | "PURPLE" | "TEAL" | "YELLOW" | "%future added value";
export type RoadmapSprintState = "ACTIVE" | "CLOSED" | "FUTURE" | "%future added value";
export type RoadmapTimelineMode = "MONTHS" | "QUARTERS" | "WEEKS" | "%future added value";
export type RoadmapVersionStatus = "ARCHIVED" | "RELEASED" | "UNRELEASED" | "%future added value";
export type roadmapCriticalDataQuery$variables = {
  isRoadmapsCriticalDataItemFlaggedEnabled: boolean;
  locationAri?: string | null | undefined;
  sourceAri: string;
};
export type roadmapCriticalDataQuery$data = {
  readonly me: {
    readonly user: {
      readonly __typename: string;
      readonly accountId: string;
      readonly name: string;
      readonly picture: AGG$URL;
    } | null | undefined;
  };
  readonly roadmaps: {
    readonly roadmapForSource: {
      readonly content: {
        readonly configuration: {
          readonly boardConfiguration: {
            readonly childIssuePlanningMode: RoadmapChildIssuePlanningMode;
            readonly derivedFields: ReadonlyArray<{
              readonly id: string;
              readonly values: ReadonlyArray<string>;
            }>;
            readonly isBoardJqlFilteringOutEpics: boolean;
            readonly isChildIssuePlanningEnabled: boolean;
            readonly isSprintsFeatureEnabled: boolean;
            readonly isUserBoardAdmin: boolean;
            readonly jql: string;
            readonly sprints: ReadonlyArray<{
              readonly endDateRFC3339: AGG$Date;
              readonly id: string;
              readonly name: string;
              readonly startDateRFC3339: AGG$Date;
              readonly state: RoadmapSprintState;
            }>;
          };
          readonly dependencies: {
            readonly inwardDependencyDescription: string;
            readonly isDependenciesEnabled: boolean;
            readonly outwardDependencyDescription: string;
          };
          readonly externalConfiguration: {
            readonly colorField: string;
            readonly fields: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
            }>;
            readonly rankField: string;
            readonly sprintField: string;
            readonly startDateField: string;
          };
          readonly hierarchyConfiguration: {
            readonly levelOneName: string;
          };
          readonly isCrossProject: boolean;
          readonly projectConfiguration: {
            readonly childItemTypes: ReadonlyArray<{
              readonly iconUrl: string;
              readonly id: string;
              readonly name: string;
              readonly requiredFieldIds: ReadonlyArray<string>;
            }>;
            readonly components: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
            }>;
            readonly defaultItemTypeId: string | null | undefined;
            readonly isGoalsFeatureEnabled: boolean;
            readonly isReleasesFeatureEnabled: boolean;
            readonly parentItemTypes: ReadonlyArray<{
              readonly iconUrl: string;
              readonly id: string;
              readonly name: string;
              readonly requiredFieldIds: ReadonlyArray<string>;
            }>;
            readonly permissions: {
              readonly canAdministerProjects: boolean;
              readonly canCreateIssues: boolean;
              readonly canEditIssues: boolean;
              readonly canScheduleIssues: boolean;
            };
            readonly projectId: string;
            readonly projectKey: string;
            readonly projectName: string;
            readonly versions: ReadonlyArray<{
              readonly id: string;
              readonly name: string;
              readonly releaseDate: AGG$Date | null | undefined;
              readonly status: RoadmapVersionStatus;
            }>;
          };
          readonly rankIssuesSupported: boolean;
          readonly statusCategories: ReadonlyArray<{
            readonly id: string;
            readonly key: string;
            readonly name: string;
          }>;
          readonly userConfiguration: {
            readonly creationPreferences: {
              readonly itemTypes: AGG$JSON;
            };
            readonly highlightedVersions: ReadonlyArray<string>;
            readonly isDependenciesVisible: boolean;
            readonly isProgressVisible: boolean;
            readonly isReleasesVisible: boolean;
            readonly isWarningsVisible: boolean;
            readonly issuePanelRatio: number | null | undefined;
            readonly levelOneViewSettings: {
              readonly period: number;
              readonly showCompleted: boolean;
            };
            readonly listWidth: AGG$Long;
            readonly timelineMode: RoadmapTimelineMode;
          };
        };
        readonly items: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly assignee: {
                readonly accountId: string;
                readonly name: string;
                readonly picture: AGG$URL;
              } | null | undefined;
              readonly color: RoadmapPaletteColor;
              readonly componentIds: ReadonlyArray<string>;
              readonly dependencies: ReadonlyArray<string>;
              readonly dueDateRFC3339: AGG$Date | null | undefined;
              readonly flagged?: boolean;
              readonly id: string;
              readonly itemTypeId: AGG$Long;
              readonly key: string;
              readonly labels: ReadonlyArray<string>;
              readonly parentId: string | null | undefined;
              readonly rank: string;
              readonly resolved: boolean;
              readonly sprintIds: ReadonlyArray<string>;
              readonly startDateRFC3339: AGG$Date | null | undefined;
              readonly status: {
                readonly id: string;
                readonly name: string;
                readonly statusCategory: {
                  readonly id: string;
                };
              } | null | undefined;
              readonly summary: string;
              readonly versionIds: ReadonlyArray<string>;
            };
          } | null | undefined>;
        };
      } | null | undefined;
      readonly healthcheck: {
        readonly explanation: string;
        readonly id: string;
        readonly learnMore: {
          readonly text: string;
          readonly url: string;
        };
        readonly resolution: {
          readonly actionId: string;
          readonly label: string;
        } | null | undefined;
        readonly title: string;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type roadmapCriticalDataQuery = {
  response: roadmapCriticalDataQuery$data;
  variables: roadmapCriticalDataQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isRoadmapsCriticalDataItemFlaggedEnabled"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "locationAri"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceAri"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "accountId"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "picture"
},
v7 = [
  {
    "kind": "Variable",
    "name": "locationARI",
    "variableName": "locationAri"
  },
  {
    "kind": "Variable",
    "name": "sourceARI",
    "variableName": "sourceAri"
  }
],
v8 = {
  "kind": "ScalarField",
  "name": "id"
},
v9 = {
  "kind": "ScalarField",
  "name": "title"
},
v10 = {
  "kind": "ScalarField",
  "name": "explanation"
},
v11 = {
  "concreteType": "RoadmapHealthCheckLink",
  "kind": "LinkedField",
  "name": "learnMore",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "text"
    },
    {
      "kind": "ScalarField",
      "name": "url"
    }
  ]
},
v12 = {
  "concreteType": "RoadmapHealthCheckResolution",
  "kind": "LinkedField",
  "name": "resolution",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "label"
    },
    {
      "kind": "ScalarField",
      "name": "actionId"
    }
  ]
},
v13 = {
  "kind": "ScalarField",
  "name": "rankIssuesSupported"
},
v14 = {
  "kind": "ScalarField",
  "name": "isCrossProject"
},
v15 = {
  "kind": "ScalarField",
  "name": "isDependenciesEnabled"
},
v16 = {
  "kind": "ScalarField",
  "name": "inwardDependencyDescription"
},
v17 = {
  "kind": "ScalarField",
  "name": "outwardDependencyDescription"
},
v18 = {
  "kind": "ScalarField",
  "name": "jql"
},
v19 = {
  "kind": "ScalarField",
  "name": "isUserBoardAdmin"
},
v20 = {
  "kind": "ScalarField",
  "name": "isBoardJqlFilteringOutEpics"
},
v21 = {
  "kind": "ScalarField",
  "name": "isSprintsFeatureEnabled"
},
v22 = {
  "kind": "ScalarField",
  "name": "isChildIssuePlanningEnabled"
},
v23 = {
  "kind": "ScalarField",
  "name": "state"
},
v24 = {
  "kind": "ScalarField",
  "name": "startDateRFC3339"
},
v25 = {
  "kind": "ScalarField",
  "name": "endDateRFC3339"
},
v26 = {
  "kind": "ScalarField",
  "name": "childIssuePlanningMode"
},
v27 = {
  "concreteType": "RoadmapField",
  "kind": "LinkedField",
  "name": "derivedFields",
  "plural": true,
  "selections": [
    (v8/*: any*/),
    {
      "kind": "ScalarField",
      "name": "values"
    }
  ]
},
v28 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v29 = {
  "kind": "ScalarField",
  "name": "projectKey"
},
v30 = {
  "kind": "ScalarField",
  "name": "projectName"
},
v31 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v32 = {
  "kind": "ScalarField",
  "name": "requiredFieldIds"
},
v33 = {
  "kind": "ScalarField",
  "name": "canEditIssues"
},
v34 = {
  "kind": "ScalarField",
  "name": "canScheduleIssues"
},
v35 = {
  "kind": "ScalarField",
  "name": "canCreateIssues"
},
v36 = {
  "kind": "ScalarField",
  "name": "canAdministerProjects"
},
v37 = {
  "kind": "ScalarField",
  "name": "status"
},
v38 = {
  "kind": "ScalarField",
  "name": "releaseDate"
},
v39 = {
  "kind": "ScalarField",
  "name": "isReleasesFeatureEnabled"
},
v40 = {
  "kind": "ScalarField",
  "name": "defaultItemTypeId"
},
v41 = {
  "kind": "ScalarField",
  "name": "isGoalsFeatureEnabled"
},
v42 = {
  "kind": "ScalarField",
  "name": "startDateField"
},
v43 = {
  "kind": "ScalarField",
  "name": "rankField"
},
v44 = {
  "kind": "ScalarField",
  "name": "sprintField"
},
v45 = {
  "kind": "ScalarField",
  "name": "colorField"
},
v46 = [
  (v8/*: any*/),
  (v5/*: any*/)
],
v47 = {
  "concreteType": "RoadmapFieldConfiguration",
  "kind": "LinkedField",
  "name": "fields",
  "plural": true,
  "selections": (v46/*: any*/)
},
v48 = {
  "kind": "ScalarField",
  "name": "key"
},
v49 = {
  "kind": "ScalarField",
  "name": "itemTypes"
},
v50 = {
  "kind": "ScalarField",
  "name": "isDependenciesVisible"
},
v51 = {
  "kind": "ScalarField",
  "name": "isProgressVisible"
},
v52 = {
  "kind": "ScalarField",
  "name": "isWarningsVisible"
},
v53 = {
  "kind": "ScalarField",
  "name": "listWidth"
},
v54 = {
  "kind": "ScalarField",
  "name": "timelineMode"
},
v55 = {
  "kind": "ScalarField",
  "name": "period"
},
v56 = {
  "kind": "ScalarField",
  "name": "showCompleted"
},
v57 = {
  "kind": "ScalarField",
  "name": "isReleasesVisible"
},
v58 = {
  "kind": "ScalarField",
  "name": "highlightedVersions"
},
v59 = {
  "kind": "ScalarField",
  "name": "issuePanelRatio"
},
v60 = {
  "kind": "ScalarField",
  "name": "levelOneName"
},
v61 = {
  "kind": "ScalarField",
  "name": "parentId"
},
v62 = {
  "kind": "ScalarField",
  "name": "summary"
},
v63 = {
  "kind": "ScalarField",
  "name": "dueDateRFC3339"
},
v64 = {
  "kind": "ScalarField",
  "name": "color"
},
v65 = {
  "kind": "ScalarField",
  "name": "dependencies"
},
v66 = {
  "kind": "ScalarField",
  "name": "itemTypeId"
},
v67 = {
  "kind": "ScalarField",
  "name": "rank"
},
v68 = {
  "kind": "ScalarField",
  "name": "labels"
},
v69 = {
  "kind": "ScalarField",
  "name": "sprintIds"
},
v70 = {
  "kind": "ScalarField",
  "name": "versionIds"
},
v71 = {
  "kind": "ScalarField",
  "name": "componentIds"
},
v72 = {
  "kind": "ScalarField",
  "name": "resolved"
},
v73 = {
  "kind": "ScalarField",
  "name": "flagged"
},
v74 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v8/*: any*/)
],
v75 = [
  (v8/*: any*/),
  (v31/*: any*/),
  (v5/*: any*/),
  (v32/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "roadmapCriticalDataQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "AuthenticationContext",
          "kind": "LinkedField",
          "name": "me",
          "plural": false,
          "selections": [
            {
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": (v3/*: any*/),
                  "action": "THROW",
                  "path": "me.user.__typename"
                },
                {
                  "kind": "RequiredField",
                  "field": (v4/*: any*/),
                  "action": "THROW",
                  "path": "me.user.accountId"
                },
                {
                  "kind": "RequiredField",
                  "field": (v5/*: any*/),
                  "action": "THROW",
                  "path": "me.user.name"
                },
                {
                  "kind": "RequiredField",
                  "field": (v6/*: any*/),
                  "action": "THROW",
                  "path": "me.user.picture"
                }
              ]
            }
          ]
        },
        "action": "THROW",
        "path": "me"
      },
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "RoadmapDetails",
            "kind": "LinkedField",
            "name": "roadmapForSource",
            "plural": false,
            "selections": [
              {
                "concreteType": "RoadmapHealthCheck",
                "kind": "LinkedField",
                "name": "healthcheck",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v8/*: any*/),
                    "action": "THROW",
                    "path": "roadmaps.roadmapForSource.healthcheck.id"
                  },
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              {
                "concreteType": "RoadmapContent",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "RoadmapConfiguration",
                      "kind": "LinkedField",
                      "name": "configuration",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v13/*: any*/),
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.rankIssuesSupported"
                        },
                        {
                          "kind": "RequiredField",
                          "field": (v14/*: any*/),
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.isCrossProject"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "RoadmapDependencyConfiguration",
                            "kind": "LinkedField",
                            "name": "dependencies",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v15/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.dependencies.isDependenciesEnabled"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v16/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.dependencies.inwardDependencyDescription"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v17/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.dependencies.outwardDependencyDescription"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.dependencies"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "RoadmapBoardConfiguration",
                            "kind": "LinkedField",
                            "name": "boardConfiguration",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v18/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.jql"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v19/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.isUserBoardAdmin"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v20/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.isBoardJqlFilteringOutEpics"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v21/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.isSprintsFeatureEnabled"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v22/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.isChildIssuePlanningEnabled"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapSprint",
                                  "kind": "LinkedField",
                                  "name": "sprints",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v8/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.sprints.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.sprints.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v23/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.sprints.state"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v24/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.sprints.startDateRFC3339"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v25/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.sprints.endDateRFC3339"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.sprints"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v26/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.childIssuePlanningMode"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v27/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration.derivedFields"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.boardConfiguration"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "RoadmapProjectConfiguration",
                            "kind": "LinkedField",
                            "name": "projectConfiguration",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v28/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.projectId"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v29/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.projectKey"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v30/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.projectName"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapItemType",
                                  "kind": "LinkedField",
                                  "name": "parentItemTypes",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v8/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.parentItemTypes.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v31/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.parentItemTypes.iconUrl"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.parentItemTypes.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v32/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.parentItemTypes.requiredFieldIds"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.parentItemTypes"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapItemType",
                                  "kind": "LinkedField",
                                  "name": "childItemTypes",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v8/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.childItemTypes.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v31/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.childItemTypes.iconUrl"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.childItemTypes.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v32/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.childItemTypes.requiredFieldIds"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.childItemTypes"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapProjectPermissions",
                                  "kind": "LinkedField",
                                  "name": "permissions",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v33/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.permissions.canEditIssues"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v34/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.permissions.canScheduleIssues"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v35/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.permissions.canCreateIssues"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v36/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.permissions.canAdministerProjects"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.permissions"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapVersion",
                                  "kind": "LinkedField",
                                  "name": "versions",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v8/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.versions.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.versions.name"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v37/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.versions.status"
                                    },
                                    (v38/*: any*/)
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.versions"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapComponent",
                                  "kind": "LinkedField",
                                  "name": "components",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v8/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.components.id"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v5/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.components.name"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.components"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v39/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration.isReleasesFeatureEnabled"
                              },
                              (v40/*: any*/),
                              (v41/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.projectConfiguration"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "RoadmapExternalConfiguration",
                            "kind": "LinkedField",
                            "name": "externalConfiguration",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v42/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.externalConfiguration.startDateField"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v43/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.externalConfiguration.rankField"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v44/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.externalConfiguration.sprintField"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v45/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.externalConfiguration.colorField"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v47/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.externalConfiguration.fields"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.externalConfiguration"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "RoadmapStatusCategory",
                            "kind": "LinkedField",
                            "name": "statusCategories",
                            "plural": true,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v8/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.statusCategories.id"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v48/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.statusCategories.key"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v5/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.statusCategories.name"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.statusCategories"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "RoadmapUserConfiguration",
                            "kind": "LinkedField",
                            "name": "userConfiguration",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapCreationPreferences",
                                  "kind": "LinkedField",
                                  "name": "creationPreferences",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v49/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.creationPreferences.itemTypes"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.creationPreferences"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v50/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.isDependenciesVisible"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v51/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.isProgressVisible"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v52/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.isWarningsVisible"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v53/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.listWidth"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v54/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.timelineMode"
                              },
                              {
                                "kind": "RequiredField",
                                "field": {
                                  "concreteType": "RoadmapViewSettings",
                                  "kind": "LinkedField",
                                  "name": "levelOneViewSettings",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "kind": "RequiredField",
                                      "field": (v55/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.levelOneViewSettings.period"
                                    },
                                    {
                                      "kind": "RequiredField",
                                      "field": (v56/*: any*/),
                                      "action": "THROW",
                                      "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.levelOneViewSettings.showCompleted"
                                    }
                                  ]
                                },
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.levelOneViewSettings"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v57/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.isReleasesVisible"
                              },
                              {
                                "kind": "RequiredField",
                                "field": (v58/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration.highlightedVersions"
                              },
                              (v59/*: any*/)
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.userConfiguration"
                        },
                        {
                          "kind": "RequiredField",
                          "field": {
                            "concreteType": "RoadmapHierarchyConfiguration",
                            "kind": "LinkedField",
                            "name": "hierarchyConfiguration",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "RequiredField",
                                "field": (v60/*: any*/),
                                "action": "THROW",
                                "path": "roadmaps.roadmapForSource.content.configuration.hierarchyConfiguration.levelOneName"
                              }
                            ]
                          },
                          "action": "THROW",
                          "path": "roadmaps.roadmapForSource.content.configuration.hierarchyConfiguration"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "roadmaps.roadmapForSource.content.configuration"
                  },
                  {
                    "concreteType": "RoadmapItemConnection",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "RequiredField",
                        "field": {
                          "concreteType": "RoadmapItemEdge",
                          "kind": "LinkedField",
                          "name": "edges",
                          "plural": true,
                          "selections": [
                            {
                              "kind": "RequiredField",
                              "field": {
                                "concreteType": "RoadmapItem",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "kind": "RequiredField",
                                    "field": (v8/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.id"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v48/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.key"
                                  },
                                  (v61/*: any*/),
                                  {
                                    "kind": "RequiredField",
                                    "field": (v62/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.summary"
                                  },
                                  (v24/*: any*/),
                                  (v63/*: any*/),
                                  {
                                    "kind": "RequiredField",
                                    "field": (v64/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.color"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v65/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.dependencies"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v66/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.itemTypeId"
                                  },
                                  {
                                    "kind": "LinkedField",
                                    "name": "assignee",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v4/*: any*/),
                                        "action": "THROW",
                                        "path": "roadmaps.roadmapForSource.content.items.edges.node.assignee.accountId"
                                      },
                                      {
                                        "kind": "RequiredField",
                                        "field": (v5/*: any*/),
                                        "action": "THROW",
                                        "path": "roadmaps.roadmapForSource.content.items.edges.node.assignee.name"
                                      },
                                      {
                                        "kind": "RequiredField",
                                        "field": (v6/*: any*/),
                                        "action": "THROW",
                                        "path": "roadmaps.roadmapForSource.content.items.edges.node.assignee.picture"
                                      }
                                    ]
                                  },
                                  {
                                    "concreteType": "RoadmapItemStatus",
                                    "kind": "LinkedField",
                                    "name": "status",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v8/*: any*/),
                                        "action": "THROW",
                                        "path": "roadmaps.roadmapForSource.content.items.edges.node.status.id"
                                      },
                                      {
                                        "kind": "RequiredField",
                                        "field": (v5/*: any*/),
                                        "action": "THROW",
                                        "path": "roadmaps.roadmapForSource.content.items.edges.node.status.name"
                                      },
                                      {
                                        "kind": "RequiredField",
                                        "field": {
                                          "concreteType": "RoadmapItemStatusCategory",
                                          "kind": "LinkedField",
                                          "name": "statusCategory",
                                          "plural": false,
                                          "selections": [
                                            {
                                              "kind": "RequiredField",
                                              "field": (v8/*: any*/),
                                              "action": "THROW",
                                              "path": "roadmaps.roadmapForSource.content.items.edges.node.status.statusCategory.id"
                                            }
                                          ]
                                        },
                                        "action": "THROW",
                                        "path": "roadmaps.roadmapForSource.content.items.edges.node.status.statusCategory"
                                      }
                                    ]
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v67/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.rank"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v68/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.labels"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v69/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.sprintIds"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v70/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.versionIds"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v71/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.componentIds"
                                  },
                                  {
                                    "kind": "RequiredField",
                                    "field": (v72/*: any*/),
                                    "action": "THROW",
                                    "path": "roadmaps.roadmapForSource.content.items.edges.node.resolved"
                                  },
                                  {
                                    "condition": "isRoadmapsCriticalDataItemFlaggedEnabled",
                                    "kind": "Condition",
                                    "passingValue": true,
                                    "selections": [
                                      {
                                        "kind": "RequiredField",
                                        "field": (v73/*: any*/),
                                        "action": "THROW",
                                        "path": "roadmaps.roadmapForSource.content.items.edges.node.flagged"
                                      }
                                    ]
                                  }
                                ]
                              },
                              "action": "THROW",
                              "path": "roadmaps.roadmapForSource.content.items.edges.node"
                            }
                          ]
                        },
                        "action": "THROW",
                        "path": "roadmaps.roadmapForSource.content.items.edges"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "roadmapCriticalDataQuery",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": (v74/*: any*/)
          }
        ]
      },
      {
        "concreteType": "RoadmapsQuery",
        "kind": "LinkedField",
        "name": "roadmaps",
        "plural": false,
        "selections": [
          {
            "args": (v7/*: any*/),
            "concreteType": "RoadmapDetails",
            "kind": "LinkedField",
            "name": "roadmapForSource",
            "plural": false,
            "selections": [
              {
                "concreteType": "RoadmapHealthCheck",
                "kind": "LinkedField",
                "name": "healthcheck",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/)
                ]
              },
              {
                "concreteType": "RoadmapContent",
                "kind": "LinkedField",
                "name": "content",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "RoadmapConfiguration",
                    "kind": "LinkedField",
                    "name": "configuration",
                    "plural": false,
                    "selections": [
                      (v13/*: any*/),
                      (v14/*: any*/),
                      {
                        "concreteType": "RoadmapDependencyConfiguration",
                        "kind": "LinkedField",
                        "name": "dependencies",
                        "plural": false,
                        "selections": [
                          (v15/*: any*/),
                          (v16/*: any*/),
                          (v17/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "RoadmapBoardConfiguration",
                        "kind": "LinkedField",
                        "name": "boardConfiguration",
                        "plural": false,
                        "selections": [
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          {
                            "concreteType": "RoadmapSprint",
                            "kind": "LinkedField",
                            "name": "sprints",
                            "plural": true,
                            "selections": [
                              (v8/*: any*/),
                              (v5/*: any*/),
                              (v23/*: any*/),
                              (v24/*: any*/),
                              (v25/*: any*/)
                            ]
                          },
                          (v26/*: any*/),
                          (v27/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "RoadmapProjectConfiguration",
                        "kind": "LinkedField",
                        "name": "projectConfiguration",
                        "plural": false,
                        "selections": [
                          (v28/*: any*/),
                          (v29/*: any*/),
                          (v30/*: any*/),
                          {
                            "concreteType": "RoadmapItemType",
                            "kind": "LinkedField",
                            "name": "parentItemTypes",
                            "plural": true,
                            "selections": (v75/*: any*/)
                          },
                          {
                            "concreteType": "RoadmapItemType",
                            "kind": "LinkedField",
                            "name": "childItemTypes",
                            "plural": true,
                            "selections": (v75/*: any*/)
                          },
                          {
                            "concreteType": "RoadmapProjectPermissions",
                            "kind": "LinkedField",
                            "name": "permissions",
                            "plural": false,
                            "selections": [
                              (v33/*: any*/),
                              (v34/*: any*/),
                              (v35/*: any*/),
                              (v36/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "RoadmapVersion",
                            "kind": "LinkedField",
                            "name": "versions",
                            "plural": true,
                            "selections": [
                              (v8/*: any*/),
                              (v5/*: any*/),
                              (v37/*: any*/),
                              (v38/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "RoadmapComponent",
                            "kind": "LinkedField",
                            "name": "components",
                            "plural": true,
                            "selections": (v46/*: any*/)
                          },
                          (v39/*: any*/),
                          (v40/*: any*/),
                          (v41/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "RoadmapExternalConfiguration",
                        "kind": "LinkedField",
                        "name": "externalConfiguration",
                        "plural": false,
                        "selections": [
                          (v42/*: any*/),
                          (v43/*: any*/),
                          (v44/*: any*/),
                          (v45/*: any*/),
                          (v47/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "RoadmapStatusCategory",
                        "kind": "LinkedField",
                        "name": "statusCategories",
                        "plural": true,
                        "selections": [
                          (v8/*: any*/),
                          (v48/*: any*/),
                          (v5/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "RoadmapUserConfiguration",
                        "kind": "LinkedField",
                        "name": "userConfiguration",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "RoadmapCreationPreferences",
                            "kind": "LinkedField",
                            "name": "creationPreferences",
                            "plural": false,
                            "selections": [
                              (v49/*: any*/)
                            ]
                          },
                          (v50/*: any*/),
                          (v51/*: any*/),
                          (v52/*: any*/),
                          (v53/*: any*/),
                          (v54/*: any*/),
                          {
                            "concreteType": "RoadmapViewSettings",
                            "kind": "LinkedField",
                            "name": "levelOneViewSettings",
                            "plural": false,
                            "selections": [
                              (v55/*: any*/),
                              (v56/*: any*/)
                            ]
                          },
                          (v57/*: any*/),
                          (v58/*: any*/),
                          (v59/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "RoadmapHierarchyConfiguration",
                        "kind": "LinkedField",
                        "name": "hierarchyConfiguration",
                        "plural": false,
                        "selections": [
                          (v60/*: any*/)
                        ]
                      }
                    ]
                  },
                  {
                    "concreteType": "RoadmapItemConnection",
                    "kind": "LinkedField",
                    "name": "items",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "RoadmapItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "concreteType": "RoadmapItem",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v48/*: any*/),
                              (v61/*: any*/),
                              (v62/*: any*/),
                              (v24/*: any*/),
                              (v63/*: any*/),
                              (v64/*: any*/),
                              (v65/*: any*/),
                              (v66/*: any*/),
                              {
                                "kind": "LinkedField",
                                "name": "assignee",
                                "plural": false,
                                "selections": (v74/*: any*/)
                              },
                              {
                                "concreteType": "RoadmapItemStatus",
                                "kind": "LinkedField",
                                "name": "status",
                                "plural": false,
                                "selections": [
                                  (v8/*: any*/),
                                  (v5/*: any*/),
                                  {
                                    "concreteType": "RoadmapItemStatusCategory",
                                    "kind": "LinkedField",
                                    "name": "statusCategory",
                                    "plural": false,
                                    "selections": [
                                      (v8/*: any*/)
                                    ]
                                  }
                                ]
                              },
                              (v67/*: any*/),
                              (v68/*: any*/),
                              (v69/*: any*/),
                              (v70/*: any*/),
                              (v71/*: any*/),
                              (v72/*: any*/),
                              {
                                "condition": "isRoadmapsCriticalDataItemFlaggedEnabled",
                                "kind": "Condition",
                                "passingValue": true,
                                "selections": [
                                  (v73/*: any*/)
                                ]
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "2f62fab508c4c1d6c73becead73b39862c23b2280917c3e51a62e682652e8915",
    "metadata": {},
    "name": "roadmapCriticalDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "6ebc0ee4f969506fa6b95c5a28a15c45";

export default node;
