import React, { useCallback, useRef } from 'react';
import type { IssueId } from '@atlassian/jira-shared-types/src/general.tsx';

import type { Position } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/common/types/common.tsx';
import { RoadmapBarIcon } from '@atlassian/jira-software-roadmap-timeline-table-kit/src/ui/chart-item-content/date-content/bar/bar-content/bar-icon/index.tsx';
import { useFocusMarshal } from '@atlassian/timeline-table/common/context/side-effect-marshal/focus-marshal';

type Props = {
	hasImplicitDependency: boolean;
	level: number;
	dependencies: IssueId[];
	dependees: IssueId[];
	isColorInverted?: boolean;
	onClickIcon: (
		fromIds: IssueId[],
		toIds: IssueId[],
		position: Position,
		triggerRef?: React.RefObject<HTMLElement>,
	) => void;
};

const BarIcon = ({
	level,
	dependencies,
	dependees,
	hasImplicitDependency,
	isColorInverted,
	onClickIcon,
}: Props) => {
	const { shouldPreventNavigation } = useFocusMarshal();
	const triggerRef = useRef<HTMLDivElement>(null);

	const onClick = useCallback(
		(position: Position) => {
			shouldPreventNavigation(true);

			onClickIcon(dependencies, dependees, position, triggerRef);
		},
		[shouldPreventNavigation, onClickIcon, dependencies, dependees],
	);

	if (dependencies.length === 0 && dependees.length === 0 && !hasImplicitDependency) {
		return null;
	}

	return (
		<RoadmapBarIcon
			level={level}
			onClick={onClick}
			isColorInverted={isColorInverted}
			ref={triggerRef}
		/>
	);
};

export default BarIcon;
