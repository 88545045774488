import React from 'react';
import { styled as styled2 } from '@compiled/react';
import AKReleaseIcon from '@atlaskit/icon/core/migration/release--ship';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import type { Props } from './types.tsx';

const ReleaseIcon = ({ size }: Props) => (
	<Wrapper size={size}>
		<AKReleaseIcon
			label=""
			spacing={size === 'medium' ? 'spacious' : 'none'}
			color="currentColor"
			LEGACY_size={size}
		/>
	</Wrapper>
);

ReleaseIcon.defaultProps = {
	size: 'small',
};

export { ReleaseIcon };

const SMALL_ICON_SIZE = gridSize * 2;
const MEDIUM_ICON_SIZE = gridSize * 3;

const getIconSize = ({ size }: Props) => (size === 'small' ? SMALL_ICON_SIZE : MEDIUM_ICON_SIZE);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled2.div<Props>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${getIconSize}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: `${getIconSize}px`,
	marginRight: token('space.050'),
});
