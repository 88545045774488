/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { css, jsx } from '@compiled/react';

import { N10A } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { secondaryBorder } from '../../../common/styled/timeline';

type Props = {
	isHighlightToday: boolean;
	todayColumnIndex: number;
	columnPositions: [number, number][];
};

const Columns = ({ isHighlightToday, todayColumnIndex, columnPositions }: Props) => (
	<div css={containerStyles}>
		{columnPositions.map(([left, right]: [number, number], index: number) => (
			<div
				css={columnStyles}
				key={`column-position-${index}`}
				style={{ left: `${left}%`, right: `${right}%` }}
			>
				{todayColumnIndex === index && isHighlightToday ? <div css={todayColumnStyles} /> : null}
			</div>
		))}
	</div>
);

export default Columns;

const containerStyles = css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	width: '100%',
	height: '100%',
	pointerEvents: 'none',
});

const columnStyles = css({
	position: 'absolute',
	boxSizing: 'border-box',
	flex: '0 0 auto',
	top: 0,
	bottom: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	borderRight: secondaryBorder,
});

const todayColumnStyles = css({
	position: 'absolute',
	boxSizing: 'border-box',
	top: 0,
	right: '-1px',
	left: '-1px',
	height: '100%',
	backgroundColor: token('color.background.neutral', 'rgba(9, 30, 66, 0.04)'),
	borderLeft: `1px solid ${token('color.border', N10A)}`,
	borderRight: `1px solid ${token('color.border', N10A)}`,
});
