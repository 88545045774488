import React, { type ReactNode, useContext, useMemo } from 'react';

import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';

import { SideEffectContext } from './context';
import { FocusMarshalProvider } from './focus-marshal';
import { Grid } from './grid';
import messages from './messages';
import { useRowState } from './row-state';
import { ROW_STATES } from './row-state/constants';
import { useStyleMarshal } from './style-marshal';
import type { CustomMarshalOptions, MarshalActions, OverrideMarshalOptions } from './types';
import { bindCustomActions, bindOverrideActions } from './utils';

type Props = {
	scope: string;
	customActions: CustomMarshalOptions;
	overrideActions: Partial<OverrideMarshalOptions>;
	ariaLabel: string;
	ariaReadOnly: boolean;
	ariaMultiselectable: boolean | undefined; // TODO: to be removed with FG project_timeline_multi-select_and_checkboxes
	children: ReactNode;
};

const SideEffectMarshal = ({
	scope,
	customActions,
	overrideActions,
	ariaLabel,
	ariaReadOnly,
	ariaMultiselectable, // TODO: to be removed with FG project_timeline_multi-select_and_checkboxes
	children,
}: Props) => {
	const { formatMessage } = useIntl();
	const styleAPI = useStyleMarshal(scope);
	const rowState = useRowState();

	/* Rather than wrap every function in useCallback, we can encapsulate everything into one useMemo.
	 * Subjectively, this ended up being the more readable approach given the number of functions.
	 */
	const defaultActions = useMemo(() => {
		const onRowMouseEnter = (id: string) => {
			if (rowState.get(id) || rowState.isAnyRowDragging()) {
				return;
			}
			styleAPI.hoverRow(id);
		};

		const onRowMouseLeave = (id: string) => {
			if (rowState.get(id) || rowState.isAnyRowDragging()) {
				return;
			}
			styleAPI.leaveRow(id);
		};

		const onRowFocus = (id: string) => {
			if (!fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')) {
				return;
			}
			if (rowState.get(id) || rowState.isAnyRowDragging()) {
				return;
			}
			rowState.set(id, ROW_STATES.FOCUSED);
			styleAPI.hoverRow(id);
		};

		const onRowBlur = (id: string) => {
			if (!fg('jsw_roadmaps_timeline_table_meatballs_menu_a11y')) {
				return;
			}
			if (rowState.get(id) !== ROW_STATES.FOCUSED || rowState.isAnyRowDragging()) {
				return;
			}
			rowState.clear(id);
			styleAPI.leaveRow(id);
		};

		const onRowSelect = (ids: string[]) => {
			ids.forEach((id) => rowState.set(id, ROW_STATES.SELECTED));
			styleAPI.selectRow(ids);
		};

		const onRowDeselect = (ids: string[]) => {
			ids.forEach((id) => rowState.clear(id));
			styleAPI.deselectRow();
		};

		const onRowGenerateDragPreview = () => {
			styleAPI.hideChildCreate();
		};

		const onRowDragStart = (id: string) => {
			rowState.set(id, ROW_STATES.DRAGGED);
			styleAPI.dragRow(id);
		};

		const onRowDragEnd = (id: string) => {
			const wasRowSelected = rowState.wasRowSelected(id);
			wasRowSelected ? rowState.set(id, ROW_STATES.SELECTED) : rowState.clear(id);
			styleAPI.dropRow(id, wasRowSelected);
		};

		/**
		 *
		 * @param id
		 * @param top
		 * @param left: Left offset when dragging at nested levels
		 */
		const onRowDragOver = (id: string, top: number, left?: number) => {
			rowState.set(id, ROW_STATES.DRAGGED_OVER);
			styleAPI.showDragIndicator(top, left);
		};

		const onRowDragChildOverParent = (id: string, parentId: string) => {
			rowState.set(id, ROW_STATES.DRAGGED_OVER);
			styleAPI.dragOverParent(parentId, rowState.get(parentId) === ROW_STATES.SELECTED);
		};

		/**
		 * Clear the background color of the parent row when dragging child over parent ends
		 * @param parentId
		 */
		const onRowDragChildOverParentEnd = (parentId: string) => {
			styleAPI.leaveRow(parentId);
		};

		const onRowDragOutOfBounds = (id: string) => {
			rowState.set(id, ROW_STATES.DRAGGED_OUT);
			styleAPI.dragOutOfBounds();
		};

		const onAddButtonMouseEnter = (top: number, tabFocused?: boolean) => {
			if (rowState.isAnyRowDragging()) {
				return;
			}
			styleAPI.showInlineCreate(top, tabFocused);
		};

		const onAddButtonMouseLeave = () => {
			if (rowState.isAnyRowDragging()) {
				return;
			}
			styleAPI.hideInlineCreate();
		};

		const onScrollStateChanged = (isScrolling: boolean) => {
			if (rowState.isAnyRowDragging()) {
				return;
			}
			isScrolling ? styleAPI.blockPointerEvents() : styleAPI.allowPointerEvents();
		};

		return {
			onRowMouseEnter,
			onRowMouseLeave,
			onRowSelect,
			onRowDeselect,
			onRowFocus,
			onRowBlur,
			onRowGenerateDragPreview,
			onRowDragStart,
			onRowDragEnd,
			onRowDragOver,
			onRowDragChildOverParent,
			onRowDragChildOverParentEnd,
			onRowDragOutOfBounds,
			onAddButtonMouseEnter,
			onAddButtonMouseLeave,
			onScrollStateChanged,
		};
	}, [rowState, styleAPI]);

	const combinedActions: MarshalActions = useMemo(
		() => ({
			...bindOverrideActions(defaultActions, overrideActions, styleAPI),
			custom: bindCustomActions(customActions, styleAPI),
		}),
		[defaultActions, customActions, overrideActions, styleAPI],
	);

	return (
		<SideEffectContext.Provider value={combinedActions}>
			<FocusMarshalProvider>
				<Grid
					scope={scope}
					ariaReadOnly={ariaReadOnly}
					ariaMultiselectable={ariaMultiselectable} // TODO: to be removed with FG project_timeline_multi-select_and_checkboxes
					ariaLabel={ariaLabel ?? formatMessage(messages.defaultLabel)}
				>
					{children}
				</Grid>
			</FocusMarshalProvider>
		</SideEffectContext.Provider>
	);
};

SideEffectMarshal.defaultProps = {
	customActions: {},
	overrideActions: {},
	ariaLabel: undefined,
	ariaReadOnly: undefined,
	ariaMultiselectable: undefined, // TODO: to be removed with FG project_timeline_multi-select_and_checkboxes
};

export default SideEffectMarshal;
export const useSideEffectMarshal = () => useContext(SideEffectContext);
