import React, { useRef, useCallback, type SyntheticEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Y300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import type { IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { INTERACTED_ITEMS_LIMIT } from '../../constants.tsx';
import messages from './messages.tsx';

export interface Props {
	id: string | number;
	issueKey: IssueKey | undefined;
	onPersist: (id: number | string, analyticsEvent: UIAnalyticsEvent) => void;
	onUndo: (id: number | string, analyticsEvent: UIAnalyticsEvent) => void;
	onDismissed?: (id: number | string, analyticsEvent: UIAnalyticsEvent) => void;
}

export const InteractedItemsLimitExceededWithActionsFlag = ({
	id,
	issueKey,
	onUndo,
	onPersist,
	onDismissed,
}: Props) => {
	const { formatMessage } = useIntl();
	const isUndoing = useRef(false);

	const handleDismissed = useCallback(
		(flagId: number | string, analyticsEvent: UIAnalyticsEvent) => {
			isUndoing.current ? onDismissed?.(flagId, analyticsEvent) : onPersist(flagId, analyticsEvent);
		},
		[onPersist, onDismissed],
	);

	const handleUndo = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			isUndoing.current = true;

			onUndo(id, analyticsEvent);
			onDismissed?.(id, analyticsEvent);
		},
		[id, onUndo, onDismissed],
	);

	const handlePersist = useCallback(
		(event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
			isUndoing.current = false;

			onPersist(id, analyticsEvent);
			onDismissed?.(id, analyticsEvent);
		},
		[id, onPersist, onDismissed],
	);

	return (
		<SuccessFlag
			id={id}
			icon={
				<WarningIcon
					color={token('color.icon.warning', Y300)}
					spacing="spacious"
					LEGACY_size="medium"
					label=""
				/>
			}
			title={formatMessage(messages.title, { issueKey })}
			description={
				<FormattedMessage
					{...(expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
						? messages.descriptionIssueTermRefresh
						: messages.description)}
					values={{
						issueKey,
						limit: INTERACTED_ITEMS_LIMIT,
						// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
						b: (chunks: string) => <b>{chunks}</b>,
					}}
				/>
			}
			onDismissed={handleDismissed}
			messageId="software-roadmap-standard.flags.interacted-items-limit-exceeded-with-actions.success-flag"
			messageType="transactional"
			actions={[
				{
					content: formatMessage(messages.undo),
					onClick: handleUndo,
				},
				{
					content: formatMessage(messages.persist),
					onClick: handlePersist,
				},
			]}
		/>
	);
};
