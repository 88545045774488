/** @jsx jsx */
import React, { useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { withDragObserver } from '@atlassian/jira-drag-observer/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type {
	BarTheme,
	BarDragType,
	HorizontalDirection,
} from '../../../../../common/types/chart-item.tsx';
import type { Position } from '../../../../../common/types/common.tsx';

type BaseProps = {
	type: HorizontalDirection;
	hasPlaceholder: boolean;
	theme: BarTheme;
};

const Handle = ({
	type,
	hasPlaceholder,
	theme,
	children,
	testid,
}: {
	testid: string;
	children?: React.ReactNode;
} & BaseProps) => {
	return (
		<>
			{isVisualRefreshEnabled() ? (
				<div
					css={handleStyles}
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						[type]: 0,
						backgroundColor:
							!theme.hasContrastSecondaryGradient && hasPlaceholder
								? theme.background
								: theme.handle,
					}}
					data-testid={testid}
				>
					{children}
				</div>
			) : (
				<div
					css={handleStylesOld}
					style={{
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						[type]: 0,
						backgroundColor:
							!theme.hasContrastSecondaryGradient && hasPlaceholder
								? theme.background
								: theme.handle,
					}}
					data-testid={testid}
				>
					{children}
				</div>
			)}
		</>
	);
};

const DraggableHandle = withDragObserver(Handle, 0.1);

export type DragActions = {
	onDragStart: (type: BarDragType) => void;
	onDrag: (from: Position, to: Position, start: Position) => void;
	onDragEnd: () => void;
};

type Props = {
	id: string;
} & DragActions &
	BaseProps;

const RoadmapDragHandle = ({
	id,
	type,
	theme,
	hasPlaceholder,
	onDragStart,
	onDrag,
	onDragEnd,
}: Props) => {
	const onDragHandleStart = useCallback(() => {
		onDragStart(type);
	}, [type, onDragStart]);

	return (
		<DraggableHandle
			// NOTE: ts error needs to be fixed in withDragObserver from @atlassian/jira-drag-observer
			// @ts-expect-error - TS2322 - Type '{ "testid": string; theme: BarTheme; position: HorizontalDirection; hasPlaceholder: boolean; onDragStart: () => void; onDrag: (from: Position, to: Position, start: Position) => void; onDragEnd: () => void; }' is not assignable to type 'IntrinsicAttributes & IntrinsicClassAttributes<DragObserver> & Pick<Readonly<Props> & Readonly<...>, "children"> & Partial<...> & Partial<...>'.
			testid={`roadmap.timeline-table.components.bar.draggable.${type.toLowerCase()}-draggable-handle-${id}`}
			theme={theme}
			type={type.toLowerCase()}
			hasPlaceholder={hasPlaceholder}
			onDragStart={onDragHandleStart}
			onDrag={onDrag}
			onDragEnd={onDragEnd}
		/>
	);
};

export { RoadmapDragHandle };

const handleStyles = css({
	position: 'absolute',
	top: 0,
	bottom: 0,
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	width: '4px',
	borderRadius: '8px',
	backgroundClip: 'content-box',
	cursor: 'col-resize',
});

const handleStylesOld = css({
	position: 'absolute',
	top: 0,
	bottom: 0,
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
	width: '6px',
	borderRadius: '8px',
	backgroundClip: 'content-box',
	cursor: 'col-resize',
});
