import React, { useRef, useCallback, type SyntheticEvent } from 'react';
import noop from 'lodash/noop';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-auto-dismiss-flag'; // ignore-for-ENGHEALTH-17759
import { FormattedMessage } from '@atlassian/jira-intl';
import { useIntlV2 as useIntl } from '@atlassian/jira-intl/src/v2/use-intl.tsx';
import { ASSIGN, type SprintScopeChangeDirection } from '../../state/flags/types.tsx';
import messages from './messages.tsx';

type Props = {
	issueKey: string | undefined;
	id: string | number;
	changedSprintName: string | undefined;
	direction: SprintScopeChangeDirection;
	onDismissed: (id: number | string, event: UIAnalyticsEvent) => void;
	onPersist: (id: number | string, event: UIAnalyticsEvent) => void;
	onUndo: (id: number | string, event: UIAnalyticsEvent) => void;
};

export const SprintScopeChangeFlag = ({
	id,
	changedSprintName,
	direction,
	issueKey,
	onDismissed,
	onUndo,
	onPersist,
}: Props) => {
	const { formatMessage } = useIntl();

	const isUndoing = useRef(false);

	const onDismissedCallback = useCallback(
		(flagId: number | string, event: UIAnalyticsEvent) => {
			if (!isUndoing.current) {
				onPersist(flagId, event);
			}
			onDismissed(flagId, event);
		},
		[onDismissed, onPersist],
	);

	const onClick = useCallback(
		(_: SyntheticEvent, event: UIAnalyticsEvent) => {
			isUndoing.current = true;
			onUndo(id, event);
			onDismissed(id, event);
		},
		[onUndo, id, onDismissed],
	);

	const getMessage = () => {
		return direction === ASSIGN ? messages.descriptionAssign : messages.descriptionUnassign;
	};

	return (
		<SuccessFlag
			id={id}
			title={formatMessage(messages.title)}
			description={
				<FormattedMessage
					{...getMessage()}
					values={{
						changedSprintName,
						issueKey,
						// @ts-expect-error - Type '(chunks: string) => JSX.Element' is not assignable to type 'ReactNode'.
						b: (chunks: string) => <b>{chunks}</b>,
					}}
				/>
			}
			onDismissed={onDismissedCallback}
			messageId="software-roadmap-standard.flags.sprint-scope-change.success-flag"
			messageType="transactional"
			actions={[
				{
					content: formatMessage(messages.undo),
					onClick,
				},
			]}
		/>
	);
};

SprintScopeChangeFlag.defaultProps = {
	onDismissed: noop,
};

export default SprintScopeChangeFlag;
