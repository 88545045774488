import { useEffect } from 'react';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { actions } from './actions/index.tsx';
import type { State, HasSeenItKey } from './types.tsx';

// Store inits empty - small optimization in case consumers aren't fetching/needing any keys due to
// what's rendered on the page or code FF'd off etc., keys will be added after first get / set
const initialState: State = {};
type Actions = typeof actions;

// Temporary store we can use for onboarding experiments since have-i-seen-it services is recommended NOT to be used
// Later hopefully we can use Post office/the choreographer/choreographed components instead which should add "seen-it"
// functionality (will be later down the track - they are focusing on solving message collisions first)
const store = createStore<State, Actions>({
	name: 'jira-onboarding-has-seen-it',
	initialState,
	actions,
});

export const useHasSeenItForKey = createHook(store, {
	selector: (state: State, hasSeenItKey: HasSeenItKey) => {
		return state[hasSeenItKey];
	},
});

/**
 * Fetches & caches the has-seen-it value for the given key as well as returning access to the store
 *
 * @param {Object} params - The parameters for the hook.
 * @param {HasSeenItKey} params.hasSeenItKey - The key representing the item to check.
 * @param {boolean} [params.skipFetch=false] - Optional flag to skip fetching data. This is useful for gating when the key should never be fetched,
 * but note there is already caching logic within deeper within the React Sweet State logic to only fetch once
 * @returns {ReturnType<typeof useHasSeenItForKey>} - The store tuple [hasSeenKeyValue, actions].
 */
export const useHasSeenIt = ({
	hasSeenItKey,
	skipFetch = false,
}: {
	hasSeenItKey: HasSeenItKey;
	skipFetch?: boolean;
}) => {
	const useLastFetchedHasSeenItResult = useHasSeenItForKey(hasSeenItKey);
	const [, { fetchHasSeen }] = useLastFetchedHasSeenItResult;

	useEffect(() => {
		if (skipFetch) {
			return;
		}
		fetchHasSeen(hasSeenItKey);
	}, [fetchHasSeen, hasSeenItKey, skipFetch]);

	return useLastFetchedHasSeenItResult;
};
