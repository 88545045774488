import type {
	ProjectId,
	ProjectKey,
	IssueTypeId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import type { IdentifiableHash } from '../common/index.tsx';
import type { ComponentsHash } from '../component/index.tsx';
import type { CustomFiltersHash } from '../custom-filter/index.tsx';
import type { IssueTypeHash } from '../issue-type/index.tsx';
import type { QuickFiltersHash } from '../quick-filter/index.tsx';
import type { SprintsHash, SprintId } from '../sprint/index.tsx';
import type { VersionsHash } from '../version/index.tsx';

export const DATE = 'DATE' as const;
export const SPRINT = 'SPRINT' as const;
export const DISABLED = 'DISABLED' as const;

export type PlanningMode = typeof DATE | typeof SPRINT | typeof DISABLED;

export type Permissions = {
	canCreateIssues: boolean;
	canEditIssues: boolean;
	canScheduleIssues: boolean;
	canAdministerProjects: boolean;
};

export type RequiredFieldsHash = IdentifiableHash<IssueTypeId, string[]>;

export type BoardConfiguration = {
	isUserBoardAdmin: boolean;
	jql: string | null;
	isBoardJqlFilteringOutEpics: boolean;
	isSprintsFeatureEnabled: boolean;
	isChildIssuePlanningEnabled: boolean;
	sprints: {
		hash: SprintsHash;
		sequence: SprintId[];
	};
	childIssuePlanningMode: PlanningMode;
};

export type ProjectConfiguration = {
	id: ProjectId;
	name: string;
	key: ProjectKey;
	permissions: Permissions;
	epicIssueTypeIds: IssueTypeId[];
	requiredFields: RequiredFieldsHash;
	issueTypes: {
		hash: IssueTypeHash;
	};
	versions: {
		hash: VersionsHash;
	};
	components: {
		hash: ComponentsHash;
	};
	defaultItemTypeId: IssueTypeId | undefined;
	isReleasesFeatureEnabled: boolean | undefined;
	isGoalsFeatureEnabled: boolean | undefined;
};

export type HierarchyConfiguration = {
	levelOneName: string;
};

export type FieldConfiguration = {
	id: string;
	name: string;
};

export type Configuration = {
	startDateCustomFieldId: string;
	lexoRankCustomFieldId: string;
	colorCustomFieldId: string;
	sprintCustomFieldId: string;
	today: number;
	boardConfiguration: BoardConfiguration;
	projectConfiguration: ProjectConfiguration;
	dependenciesSupported: boolean;
	inwardDependencyDescription: string | undefined;
	outwardDependencyDescription: string | undefined;
	rankIssuesSupported: boolean;
	isCrossProject: boolean;
	hierarchyConfiguration: HierarchyConfiguration;
	deriveFields: DeriveFieldsHash;
	fields: FieldConfiguration[];
	canInviteOthersToProject: boolean;
};

export type FilterConfiguration = {
	quickFilters: QuickFiltersHash;
	customFilters: CustomFiltersHash;
};

export type DeriveFieldsHash = IdentifiableHash<string, string[]>;
