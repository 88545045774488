import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import { expVal } from '@atlassian/jira-feature-experiments';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { docs } from '../../utils/docs.tsx';
import messages from './messages.tsx';

export type Props = {
	id: string | number;
	onDismissed: (id: string | number) => void;
};

const HiddenDependencyCreatedFlag = ({ id, onDismissed }: Props) => {
	const onDismissedCallback = useCallback(() => {
		onDismissed(id);
	}, [id, onDismissed]);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const onLearnMoreCallback = useCallback(() => {
		const analyticsEvent = createAnalyticsEvent({
			action: 'clicked',
			actionSubject: 'button',
		});
		fireUIAnalytics(analyticsEvent, 'learnMoreUnavailableDependenciesButton');
	}, [createAnalyticsEvent]);
	const { formatMessage } = useIntl();
	return (
		<ErrorFlag
			id={id}
			onDismissed={onDismissedCallback}
			title={formatMessage(messages.title)}
			description={formatMessage(
				expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
					? messages.descriptionIssueTermRefresh
					: messages.description,
			)}
			messageId="software-roadmap-standard.flags.dependencies-unavailable.error-flag"
			messageType="transactional"
			actions={[
				{
					content: formatMessage(messages.learnMore),
					href: docs.DEPENDENCIES,
					onClick: onLearnMoreCallback,
				},
			]}
		/>
	);
};

HiddenDependencyCreatedFlag.defaultProps = { onDismissed: noop };

export default HiddenDependencyCreatedFlag;
