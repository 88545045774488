import React, { useCallback, useEffect, useRef, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import GrowDiagonalIcon from '@atlaskit/icon/core/migration/grow-diagonal--media-services-actual-size';
import ShrinkDiagonalIcon from '@atlaskit/icon/core/migration/shrink-diagonal--media-services-fit-to-page';
import { N400 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { Box, xcss } from '@atlaskit/primitives';
import type { ActiveKeyMap } from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-registry.tsx';
import Shortcuts from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcuts/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { fireUIAnalytics, type Attributes } from '@atlassian/jira-product-analytics-bridge';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

type Props = {
	testId: string;
	/** @deprecated refs are not needed for fullscreen in nav4 */
	getCustomRefs: () => (HTMLElement | null)[];
	analyticsAttributes: Attributes;
	onToggle: (isFullScreen: boolean, globalStyle?: HTMLElement) => void;
	withContainerStyles?: boolean;
	defaultFullscreen?: boolean;
	/** @deprecated controlled mode is not supported with nav4 */
	isFullscreen: boolean;
	renderAsResponsiveButton?: boolean;
	shouldRenderContent?: boolean;
	isSubtle?: boolean;
	isHidden?: boolean;
};

const removeFullScreenStyle = (customRefs: null | Array<HTMLElement | null>) => {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	document.getElementById('fullscreen-global-style')?.remove();
	if (customRefs) {
		customRefs.forEach((ref) => {
			if (ref !== null) {
				ref.classList.remove('fullscreen');
			}
		});
	}
};

const FullscreenButton = ({
	testId,
	onToggle,
	getCustomRefs,
	analyticsAttributes: extraAnalyticsAttributes,
	withContainerStyles = true,
	defaultFullscreen = false,
	isFullscreen: isFullscreenProp,
	renderAsResponsiveButton = false,
	shouldRenderContent = true,
	isSubtle = false,
	isHidden,
}: Props) => {
	const { formatMessage } = useIntl();
	const customRefElements = useRef<(HTMLElement | null)[] | null>(null);
	const [isFullscreenLayout, { setIsFullscreen: setIsFullScreenLayout }] = useIsFullscreen();

	let globalStyle = null;
	if (fg('blu-5747-fullscreen-in-nav-header')) {
		if (!__SERVER__) {
			globalStyle = document.createElement('style');
		}
	} else {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		globalStyle = document.createElement('style');
	}

	const isFullscreen = getWillShowNav4() ? isFullscreenLayout : isFullscreenProp;
	const handleKeysInFullScreen = useCallback(
		(e: KeyboardEvent) => {
			// Ignore if it looks like the user is typing something
			if (
				e.target instanceof HTMLElement &&
				(['INPUT', 'TEXTAREA', 'SELECT'].includes(e.target.tagName.toUpperCase()) ||
					e.target.hasAttribute('contenteditable'))
			) {
				return;
			}

			// Prevent shortcuts
			const shortcutKeysToPrevent = [
				'/', // search
				'h', // help
			];
			if (shortcutKeysToPrevent.includes(e.key)) {
				e.stopPropagation();
			}
			const navigationShortcuts = [
				'1',
				'2',
				'3',
				'4', // number keys
			];
			if (!getWillShowNav4() && navigationShortcuts.includes(e.key)) {
				// In Nav4 these keyboard shortcuts are rendered by the tab navigation
				// so they are not available in fullscreen mode
				onToggle(true, globalStyle ?? undefined);
				const customRefs = customRefElements.current;
				removeFullScreenStyle(customRefs);
			}
		},
		[globalStyle, onToggle],
	);

	useEffect(() => {
		if (isFullscreen) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.addEventListener('keydown', handleKeysInFullScreen, true);
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window?.removeEventListener('keydown', handleKeysInFullScreen, true);
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		return () => window?.removeEventListener('keydown', handleKeysInFullScreen, true);
	}, [isFullscreen, handleKeysInFullScreen]);

	const createFullScreenStyle = useCallback(() => {
		const customElements = customRefElements.current;
		const hiddenStyles =
			"#ak-jira-navigation, #ak-side-navigation, #right-sidebar-panel-wrapper, [data-skip-link-wrapper='true'] { display: none }";

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const hiddenStylesNode = document.createTextNode(hiddenStyles);

		if (globalStyle) {
			globalStyle.id = 'fullscreen-global-style';
			globalStyle.appendChild(hiddenStylesNode);
		}

		if (customElements) {
			customElements.forEach((ref: HTMLElement | null) => {
				if (ref !== null) {
					ref.classList.add('fullscreen');
				}
			});
		}

		if (globalStyle) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			document.head?.appendChild(globalStyle);
		}
	}, [globalStyle]);

	const onClick = (event: SyntheticEvent, analyticsEvent: UIAnalyticsEvent) => {
		if (getWillShowNav4()) {
			const analyticsAttributes: Attributes = {
				...extraAnalyticsAttributes,
				expandToFullscreen: !isFullscreenLayout,
			};

			fireUIAnalytics(analyticsEvent, 'fullscreenButton', analyticsAttributes);
			setIsFullScreenLayout(!isFullscreenLayout);
			onToggle(!isFullscreenLayout);
		} else {
			customRefElements.current = getCustomRefs();
			const customElements = customRefElements.current;

			const analyticsAttributes: Attributes = {
				...extraAnalyticsAttributes,
				expandToFullscreen: !isFullscreen,
			};

			fireUIAnalytics(analyticsEvent, 'fullscreenButton', analyticsAttributes);

			onToggle(isFullscreen, globalStyle ?? undefined);
			if (isFullscreen) {
				removeFullScreenStyle(customElements);
			} else {
				createFullScreenStyle();
			}
		}
	};

	useEffect(() => {
		customRefElements.current = getCustomRefs();
		const customElements = customRefElements.current;

		if (isFullscreen) {
			createFullScreenStyle();
		} else {
			removeFullScreenStyle(customElements);
		}

		return () => removeFullScreenStyle(customElements);
	}, [createFullScreenStyle, getCustomRefs, isFullscreen]);

	useEffect(() => {
		if (defaultFullscreen) {
			createFullScreenStyle();
		}
		// Empty dependency array to only apply fullscreen styling once (on mount).
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// remove this useEffect when cleaning up blu-5747-fullscreen-in-nav-header
	useEffect(() => {
		// exit fullscreen mode when moving to a different page
		return () => {
			if (getWillShowNav4() && !fg('blu-5747-fullscreen-in-nav-header')) {
				setIsFullScreenLayout(false);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const renderButtonWithTooltip = () => {
		if (isVisualRefreshEnabled()) {
			if (fg('blu-5747-fullscreen-in-nav-header')) {
				return (
					<Tooltip
						content={
							<Box xcss={KeyboardShortcutContainer}>
								{formatMessage(isFullscreen ? messages.exitFullScreen : messages.enterFullScreen)}
								<Box xcss={KeyboardShortcut}>{formatMessage(messages.fullScreenShortcut)}</Box>
							</Box>
						}
						position="top"
					>
						<ThemedButton
							{...(withContainerStyles
								? { testId }
								: { testId: 'platform.ui.fullscreen-button.fullscreen-button' })}
							onClick={onClick}
							aria-keyshortcuts="Z"
							iconBefore={
								isFullscreen ? (
									<ShrinkDiagonalIcon
										label={formatMessage(messages.exitFullScreen)}
										spacing="spacious"
									/>
								) : (
									<GrowDiagonalIcon
										label={formatMessage(messages.enterFullScreen)}
										spacing="spacious"
									/>
								)
							}
							appearance={isSubtle ? 'subtle' : 'default'}
							{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
								? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
								: {})}
						/>
					</Tooltip>
				);
			}

			return (
				<IconButton
					{...(withContainerStyles
						? { testId }
						: { testId: 'platform.ui.fullscreen-button.fullscreen-button' })}
					label={
						isFullscreen
							? formatMessage(messages.exitFullScreen)
							: formatMessage(messages.enterFullScreen)
					}
					onClick={onClick}
					icon={isFullscreen ? ShrinkDiagonalIcon : GrowDiagonalIcon}
					appearance={isSubtle ? 'subtle' : 'default'}
					isTooltipDisabled={false}
					{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
						? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
						: {})}
				/>
			);
		}

		if (renderAsResponsiveButton && !isVisualRefreshEnabled()) {
			return (
				<ResponsiveButton
					label={
						isFullscreen
							? formatMessage(messages.exitFullScreen)
							: formatMessage(messages.enterFullScreen)
					}
					icon={isFullscreen ? ShrinkDiagonalIcon : GrowDiagonalIcon}
					onClick={onClick}
					testId={testId ?? 'platform.ui.fullscreen-button.fullscreen-button'}
					{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
						? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
						: {})}
				/>
			);
		}

		return (
			<Tooltip
				content={formatMessage(isFullscreen ? messages.exitFullScreen : messages.enterFullScreen)}
				position="top-end"
			>
				<Button
					{...(withContainerStyles
						? { testId }
						: { testId: 'platform.ui.fullscreen-button.fullscreen-button' })}
					appearance="subtle"
					onClick={onClick}
					iconBefore={
						isFullscreen ? (
							<ShrinkDiagonalIcon
								color={token('color.icon')}
								label={formatMessage(messages.exitFullScreen)}
								spacing="spacious"
							/>
						) : (
							<GrowDiagonalIcon
								color={token('color.icon')}
								label={formatMessage(messages.enterFullScreen)}
								spacing="spacious"
							/>
						)
					}
					{...(fg('jsw_roadmaps_timeline-fix-a11y-rain')
						? { 'aria-hidden': isHidden, tabIndex: isHidden ? -1 : 0 }
						: {})}
				/>
			</Tooltip>
		);
	};

	const keyMap: ActiveKeyMap = {
		z: {
			callback: () => {
				setIsFullScreenLayout(!isFullscreenLayout);
			},
		},
	};

	if (!shouldRenderContent) {
		return null;
	}

	return withContainerStyles ? (
		<FullscreenButtonContainer data-testid="platform.ui.fullscreen-button.fullscreen-button">
			{renderButtonWithTooltip()}
			{getWillShowNav4() && <Shortcuts keyMap={keyMap} />}
		</FullscreenButtonContainer>
	) : (
		<>
			{renderButtonWithTooltip()}
			{getWillShowNav4() && <Shortcuts keyMap={keyMap} />}
		</>
	);
};

export default FullscreenButton;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FullscreenButtonContainer = styled.div({
	display: 'inline-flex',
	backgroundColor: token('elevation.surface.overlay'),
	boxShadow: token('elevation.shadow.overlay', '0 4px 4px rgba(0, 0, 0, 0.25)'),
	borderRadius: token('border.radius', '3px'),
});

const KeyboardShortcutContainer = xcss({
	display: 'flex',
	lineHeight: 'space.200',
	alignItems: 'center',
	paddingBottom: 'space.025',
	paddingTop: 'space.025',
});

const KeyboardShortcut = xcss({
	border: `1px solid ${token('color.text.inverse', N400)}`,
	borderRadius: '2px',
	marginLeft: 'space.050',
	paddingTop: 'space.025',
	paddingBottom: 'space.025',
	paddingRight: 'space.100',
	paddingLeft: 'space.100',
	lineHeight: 'space.200',
});
