/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Heading from '@atlaskit/heading';
import { Text } from '@atlaskit/primitives';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';

type Props = {
	title: string;
};

const RoadmapListHeaderRow = ({ title }: Props) => {
	if (isVisualRefreshEnabled()) {
		return (
			<Text maxLines={1} color="color.text.subtlest" weight="bold">
				{title}
			</Text>
		);
	}

	return (
		<Heading size="xsmall" as="div">
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p css={rowTitleStyles}>{title}</p>
		</Heading>
	);
};

export { RoadmapListHeaderRow };

const rowTitleStyles = css({
	fontWeight: token('font.weight.medium'),
	margin: 0,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
});
