/**
 * @jsxRuntime classic
 * @jsx jsx
 * @jsxFrag
 */
import React from 'react';

import { css, jsx } from '@compiled/react';
import { useIntl } from 'react-intl-next';

import { fg } from '@atlaskit/platform-feature-flags';
import { Box, xcss } from '@atlaskit/primitives';
import { B100, B50, B500, N20A, N300, N40 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { borderWidth } from '../../../../../common/styled/timeline';
import type { WeekNumber } from '../common/types';
import { inMonthOrder, inMonthOrderOld } from '../messages';

// Each day is 1/7th of a Week Cell in width
const dayWidth = (1 / 7) * 100;

const headerStylesWithVisualRefresh = css({
	fontWeight: token('font.weight.semibold', '600'),
	display: 'flex',
	justifyContent: 'start',
	width: '100%',
	paddingLeft: token('space.200', '16px'),
	marginTop: token('space.0', '0'),
});
const headerStyles = css({
	marginTop: token('space.050', '4px'),
	font: token('font.body.small'),
	color: token('color.text.subtlest', N300),
});

const headerTextTransformStyles = css({
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
});

const dayContainerStyles = css({
	display: 'flex',
	flex: '1 1 auto',
	alignItems: 'center',
	width: '100%',
	height: `calc(100% - ${token('space.050', '4px')} - ${token('space.200', '16px')})`,
});

const dayChildWeekendStyles = css({
	backgroundColor: token('color.background.neutral', N20A),
	borderRadius: token('border.radius', '3px'),
});

const dayChildWeekendStylesOld = css({
	backgroundColor: token('color.background.neutral.hovered', N40),
	borderRadius: token('border.radius', '3px'),
});

const dayChildTodayStyles = css({
	backgroundColor: token('color.background.accent.blue.subtle', B100),
	borderRadius: token('border.radius', '3px'),
});

const dayChildTodayStylesOld = css({
	borderRadius: token('border.radius', '3px'),
	backgroundColor: token('color.background.accent.blue.subtlest', B50),
	color: `${token('color.text.brand', B500)}`,
});

const dayChildStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${dayWidth}%`,
	font: token('font.body.small'),
	textAlign: 'center',
	overflowWrap: 'break-word',
	marginTop: token('space.0', '0'),
	marginRight: token('space.100', '8px'),
	marginBottom: token('space.0', '0'),
	marginLeft: token('space.100', '8px'),
	paddingTop: 0,
	paddingRight: token('space.025', '2px'),
	paddingBottom: 0,
	paddingLeft: token('space.025', '2px'),
	color: token('color.text.subtlest', N300),
});

const dayChildStylesOld = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	width: `${dayWidth}%`,
	font: token('font.body.small'),
	textAlign: 'center',
	overflowWrap: 'break-word',
	marginTop: token('space.0', '0'),
	marginRight: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	marginBottom: `${borderWidth}px`,
	marginLeft: token('space.100', '8px'),
	paddingTop: 0,
	paddingRight: token('space.025', '2px'),
	paddingBottom: 0,
	paddingLeft: token('space.025', '2px'),
	color: token('color.text.subtlest', N300),
});

const dayChildHighlightedStyles = css({
	color: token('color.text.accent.gray.bolder', N300),
});

type Props = {
	isHighlightToday: boolean;
	weekNumbers: WeekNumber[];
	columnIndex: number;
	todayCoordinates: [number, number] | undefined;
	isHighlighted: boolean;
	yearCaption?: string | undefined | null;
};

const WeekCell = ({
	columnIndex,
	weekNumbers,
	todayCoordinates,
	yearCaption,
	isHighlightToday,
	isHighlighted,
}: Props) => {
	const { formatMessage } = useIntl();

	// For the weeks granularity, the 4th day of the week determines the month that week falls in
	const monthNumber = weekNumbers[3].month;

	const todayIndex = weekNumbers.findIndex(
		(element: WeekNumber, i: number) =>
			isHighlightToday &&
			todayCoordinates &&
			todayCoordinates[0] === columnIndex &&
			todayCoordinates[1] === i,
	);

	const shouldApplyVisualRefresh =
		// eslint-disable-next-line @atlaskit/platform/no-preconditioning, @atlaskit/platform/ensure-feature-flag-prefix
		fg('platform-component-visual-refresh') && fg('visual-refresh_drop_4');

	return (
		<>
			<small
				css={[
					headerStyles,
					shouldApplyVisualRefresh && headerStylesWithVisualRefresh,
					// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
					!fg('platform-component-visual-refresh') && headerTextTransformStyles,
				]}
			>
				<Box as="span" xcss={[isHighlighted && contrastStyles]}>
					{formatMessage(
						// eslint-disable-next-line @atlaskit/platform/ensure-feature-flag-prefix
						fg('platform-component-visual-refresh')
							? inMonthOrder[monthNumber]
							: inMonthOrderOld[monthNumber],
					)}{' '}
					{yearCaption}
				</Box>
			</small>
			<div css={[dayContainerStyles]}>
				{weekNumbers.map(({ day }: WeekNumber, dayIndex: number) => (
					<span
						key={`week-number-${dayIndex}`}
						css={[
							shouldApplyVisualRefresh ? dayChildStyles : dayChildStylesOld,
							(dayIndex === 5 || dayIndex === 6) &&
								shouldApplyVisualRefresh &&
								dayChildWeekendStyles,
							(dayIndex === 5 || dayIndex === 6) &&
								!shouldApplyVisualRefresh &&
								dayChildWeekendStylesOld,
							isHighlighted && dayChildHighlightedStyles,
							todayIndex === dayIndex && shouldApplyVisualRefresh && dayChildTodayStyles,
							todayIndex === dayIndex && !shouldApplyVisualRefresh && dayChildTodayStylesOld,
						]}
					>
						{day}
					</span>
				))}
			</div>
		</>
	);
};

const contrastStyles = xcss({
	color: 'color.text.accent.gray.bolder',
});

WeekCell.defaultProps = {
	yearCaption: null,
};

export default WeekCell;
export type { Props as APIProps };
