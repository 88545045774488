import React from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button/standard-button';
import Heading from '@atlaskit/heading';
import { token } from '@atlaskit/tokens';
import GenericEmptyStateIllustration from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/generic-empty-state/index.tsx';
import MagnifyingGlassIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/magnifying-glass/index.tsx';

type IllustrationType = 'magnifying-glass' | 'generic-empty-state';

type Props = {
	title?: string;
	description: string;
	illustration?: IllustrationType;
	actionLabel?: string;
	onActionClick?: () => void;
};

const getIllustration = (illustration: IllustrationType) => {
	switch (illustration) {
		case 'generic-empty-state':
			return <GenericEmptyStateIllustration alt="" width="94" height="84" />;

		case 'magnifying-glass':
		default:
			return <MagnifyingGlassIllustration alt="" width="110" height="84" />;
	}
};

const RoadmapListEmptyContent = ({
	title,
	description,
	illustration = 'magnifying-glass',
	actionLabel,
	onActionClick,
}: Props) => {
	const showActionButton = actionLabel !== undefined && onActionClick !== undefined;

	return (
		<Container>
			{getIllustration(illustration)}
			<TextContainer>
				{title && (
					<Heading size="medium" as="span">
						{title}
					</Heading>
				)}
				<Explanation>{description}</Explanation>
				{showActionButton && (
					<Button appearance="link" onClick={onActionClick}>
						{actionLabel}
					</Button>
				)}
			</TextContainer>
		</Container>
	);
};

export { RoadmapListEmptyContent };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	paddingTop: token('space.300'),
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
	alignItems: 'center',
	backgroundColor: token('elevation.surface'),
	gap: token('space.300'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TextContainer = styled.div({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	width: '100%',
	gap: token('space.250'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Explanation = styled.div({
	marginTop: 0,
	marginRight: token('space.250'),
	marginBottom: 0,
	marginLeft: token('space.250'),
	textAlign: 'center',
	maxWidth: '350px',
});
